import React, { Fragment } from "react";
import Header from "../components/header";
import Registrer from "../components/registrer";
import Cuenta from "../components/User/cuenta";
import NavUser from "../components/User";

import {useUsuario} from '../context/user'

function Registrarse () {
    const {user}=useUsuario()

    return (
        <Fragment>
            {(user!==undefined)
                ?   <div>
                        <NavUser/>
                        <Cuenta />
                    </div>
                :   <div>
                        <Registrer/>
                    </div>
                }

        </Fragment>
    )
}

export default Registrarse