import React from "react";
import { useEffect, useState } from "react";
import sendRequest from "../../conexion";
import { useParams } from "react-router-dom";
import "./index.css";
import { Link } from "react-router-dom";

export default function AboutCard(props) {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const cargar = async () => {
    try {
      const lista = await sendRequest(
        "GET",
        "/api/v1/dashboard/information",
        null
      );
      setTitle(lista.data[parseInt(id) - 1].title);
      setDescription(lista.data[parseInt(id) - 1].description);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    cargar();
  }, []);

  return (
    <div className="body-Info">
      <h2>{title}</h2>
      <p className="description">{description}</p>
      <Link to="/About" className="link">
        <button className="button-volver">Volver</button>
      </Link>
    </div>
  );
}
