import React from "react";
import "../components/User/cuenta/cuenta.css";
import CambioDirPago from "../components/User/edicion-cuenta/info-dirPago";

export default function DirEnvioCambio() {
  return (
    <div>
      <CambioDirPago />
    </div>
  );
}
