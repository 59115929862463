import React, { useState } from "react";
import { Col, Form, Row, InputGroup, Button } from "react-bootstrap";
import { useUsuario } from "../../../context/user";
import "./cuenta.css";
import sendRequest from "../../../conexion";
import { Link } from "react-router-dom";

export default function InfoContacto() {
  const [validated, setValidated] = useState(false);
  const { user, cargarUser } = useUsuario();
  const token = localStorage.getItem("key");

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
  };

  const send = async () => {
    const name = document.getElementById("nombre").value;
    const surname = document.getElementById("Apellido").value;
    const phone = document.getElementById("phone").value;
    const dni = document.getElementById("dni").value;
    const country = document.getElementById("country").value;
    const province = document.getElementById("province").value;
    const city = document.getElementById("city").value;
    const postalCode = document.getElementById("postalCode").value;
    const streetName = document.getElementById("streetName").value;
    const nro = document.getElementById("nro").value;
    try {
      await sendRequest(
        "PUT",
        "api/v1/user",
        {
          name,
          surname,
          phone,
          dni,
          country,
          province,
          city,
          postalCode,
          streetName,
          nro,
        },
        { Authorization: token }
      );
      cargarUser(token);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Cuenta-body">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ textAlign: "start" }}
      >
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="nombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nombre"
              defaultValue={user?.name}
            />
            <Form.Control.Feedback>¡Se ve bien!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="apellido">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Apellido"
              defaultValue={user?.surname}
            />
            <Form.Control.Feedback>¡Se ve bien!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="email">
            <Form.Label>Email</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Email"
                defaultValue={user?.email}
                aria-describedby="inputGroupPrepend"
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                Por favor, elija un nombre de usuario.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3" controlId="phone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="number"
              placeholder="Teléfono"
              defaultValue={user?.phone}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un número de teléfono válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="dni">
            <Form.Label>DNI</Form.Label>
            <Form.Control
              type="number"
              placeholder="DNI"
              defaultValue={user?.dni}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un DNI válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="country">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              placeholder="País"
              defaultValue={user?.country}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un país válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="province">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ciudad"
              defaultValue={user?.province}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una ciudad válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="city" className="mt-3">
            <Form.Label>Localidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Localidad"
              defaultValue={user?.city}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una localidad válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="postalCode" className="mt-3">
            <Form.Label>Código Postal</Form.Label>
            <Form.Control
              type="number"
              placeholder="Código Postal"
              defaultValue={user?.postalCode}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un código postal válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="streetName" className="mt-3">
            <Form.Label>Calle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de la calle"
              defaultValue={user?.streetName}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una calle válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="nro" className="mt-3">
            <Form.Label>Número</Form.Label>
            <Form.Control
              type="number"
              placeholder="Número"
              defaultValue={user?.nro}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una altura de calle válida.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-5 justify-content-end">
          <Button
            onClick={send}
            variant="success"
            className="button-user"
            style={{ width: "150px" }}
          >
            Actualizar
          </Button>
          <Link
            to="/Account"
            style={{ textDecoration: "none", width: "150px" }}
          >
            <Button className="button-user" style={{ width: "150px" }}>
              Cancelar
            </Button>
          </Link>
        </Row>
      </Form>
    </div>
  );
}
