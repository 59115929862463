import React from "react";
import { useEffect, useState } from "react";
import sendRequest from "../../conexion";
import { CardGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cards/cards.css";
import Cards from "../cards/cards";
import Loader from "../utils/loader";
import { useDominio } from "../../context/dominio";

const Sale = () => {
  const { dominio } = useDominio();
  const [imagenes, SetImagen] = useState([]);
  const [estado, setEstado] = useState([]);

  const cargar = async () => {
    try {
      const response = await sendRequest(
        "GET",
        "/api/v1/allActiveProducts?subsection_id=5",
        null
      );
      let products = response.data.data;

      SetImagen(products);
      setEstado(true);
    } catch (error) {
      console.log(error);
    }
  };

  const urlImagen = (i) => {
    const primeraFoto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`;
    return primeraFoto;
  };

  const session = (i) => {
    const primeraFoto = `${i[0]}`;
    return primeraFoto;
  };

  useEffect(() => {
    cargar();
  }, []);

  return (
    <div className="Cont-Cards-TA">
      {estado === false ? (
        <Loader />
      ) : imagenes.length === 0 ? (
        <div className="Mensaje-error">NO TIENE PRODUCTOS</div>
      ) : (
        <CardGroup>
          {imagenes.map((img) => (
            <Cards
              id={img.product.id}
              name={img.product.name}
              url={urlImagen(img.productPhotos.map((i) => i.imagePath))}
              session={session(img.subsections.map((i) => i.name))}
              precio={Intl.NumberFormat("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(img.product.priceMin)}
            />
          ))}
        </CardGroup>
      )}
    </div>
  );
};

export default Sale;
