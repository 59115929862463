import React, { Fragment, useState } from "react";
import Header from "../components/header";
import Ubication from "../components/maps";
import "./view.css";

export default function Maps() {
  return (
    <Fragment>
      <div className="body-2">
        <Ubication />
      </div>
    </Fragment>
  );
}
