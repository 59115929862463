import React from 'react';
import { MainProducts } from '../../home';
import { FaShippingFast, FaStar, FaEnvelope } from 'react-icons/fa';
import { useDominio } from '../../../context/dominio';

import './homeInfo.css';

const HomeInfo = () => {
  const {dominio} = useDominio()

  return (
    <div className="homepage">
      <header className="homepage__header">
        {(dominio)
          ?<h1 className="homepage__title">¡Bienvenido a MOHAVE JEANS!</h1>
          :<h1 className="homepage__title">¡Bienvenido a GELL JEANS!</h1>}
        <p className="homepage__description">Descubre la mejor moda en jeans para mujeres.</p>
        <a href="#productos" className="homepage__cta">Ver productos destacados</a>
      </header>

      <section className="homepage__services">
        <h2 className="homepage__services-heading">Nuestros Servicios</h2>
        <div className="homepage__services-grid">
          <div className="homepage__service">
            <FaStar className="homepage__contact-icon" />
            <h3 className="homepage__service-title">Jeans de calidad</h3>
            <p className="homepage__service-description">Descubre nuestra amplia selección de jeans de alta calidad mujeres.</p>
          </div>
          <div className="homepage__service">
            <FaShippingFast className="homepage__contact-icon" />
            <h3 className="homepage__service-title">Envío rápido</h3>
            <p className="homepage__service-description">Ofrecemos envío rápido y seguro para que puedas disfrutar de tus jeans lo antes posible.</p>
          </div>
          <div className="homepage__service">
            <FaEnvelope className="homepage__contact-icon" />
            <h3 className="homepage__service-title">Servicio al cliente excepcional</h3>
            <p className="homepage__service-description">Nuestro equipo de atención al cliente está listo para ayudarte en cualquier momento.</p>
          </div>
        </div>
      </section>

      <section id="productos" className="homepage__featured-products">
        <h2 className="homepage__featured-products-heading">Productos Destacados</h2>
        <MainProducts />
      </section>
    </div>
  );
};

export default HomeInfo;