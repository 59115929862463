import React, {Fragment} from "react";
import Header from "../components/header/index";
import MetodoPago from "../components/compras/metodoPago";

export default function CompraEnvio(){
    return(
        <Fragment>
        <div className="body">
            <div className="Body-Compra">
                <MetodoPago />
            </div>
        </div>
        </Fragment>
    )
}