import React, { useEffect } from "react";
import { useState } from "react";
import './product.css'
import white from './icons/heart-white.png'
import black from './icons/heart-black.png'
import { useUsuario } from "../../context/user";
import sendRequest from "../../conexion";
import tilde from "./icons/ok.png"

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ModalLogin from "../modal-login";
import { useDominio } from "../../context/dominio";

export default function DetailProduct(props){
    const {user,addCart}=useUsuario()
    let [like,setLike] = useState(true)
    const token = localStorage.getItem('key')
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState();
    const [talle, setTalle] = useState(0)
    const [estado,setEstado] = useState(false)
    let [buttonOutlet, setButtonOutlet] = useState(0)

    const id = props.product.product.id
    const producto= props.product
    const nombre = producto.product.name
    const subsection = producto.subsections.map((section)=>section.name)
    const precio = producto.product.priceMin
    const description = producto.product.description
    const [alerta,setAlerta]=useState("")
    const [listWish,setListWish] = useState("vacio")
    const outletProduct = props.product.product.outlet
    const {outlet,line,isOutletEnable,isLineEnable} = useDominio()

    const carrito = ()=>{
        if (outlet){
            (outletProduct===true)
                ? setButtonOutlet(1)
                : setButtonOutlet(0)
        } else {
            if (line){
                (outletProduct===false)
                    ? setButtonOutlet(1)
                    : setButtonOutlet(0)
            } else {
                setButtonOutlet(1)
            }
        }
    }

    const click = async ()=>{
            if(like===true){
                await sendRequest('POST', `api/v1/wishlist/product/${id}`,{},{'Authorization': token})
                setLike(!like)
            } else {
                await sendRequest('DELETE', `api/v1/wishlist/product/${id}`,{},{'Authorization': token})
                setLike(!like)
            }
    }

    const buscarId = (id) => {
        if(listWish!=="vacio") {
            return listWish.find(elemento => elemento.product_id === id);
        }
      };

    const cargarList= async ()=>{
        if (listWish!==""){
            const lista = await sendRequest('GET','api/v1/wishlist',{},{'Authorization': token})
            setListWish(lista.data.wishlist)
        }
    }

    useEffect(()=>{
        carrito()
        if (user!==undefined) {
            cargarList()
        } else{
            setListWish("vacio")
        }
    },[outlet,line])

    useEffect(()=>{
        const existe = buscarId(id)
        if (existe!==undefined){

            setLike(false)
        } else {setLike(true)}
    },[listWish])

    const Add = (outlet)=>{
        addCart(id,radioValue,precio,outlet)
            .then((res)=>{if (res) {
                setEstado(true);
                (outlet===1)
                    ? isOutletEnable(true)
                    : isLineEnable(true)
            }})
    }

    const comprar = ()=>{
        (radioValue!== undefined)
            ? (outletProduct)
                ?Add(1)
                :Add(0)
            : setAlerta(`* Seleccionar Talle`)
    }

    const openEls = document.querySelectorAll("[data-open]");
    const isVisible = "is-visible";
    for(const el of openEls) {
    el.addEventListener("click", function() {
        const modalId = this.dataset.open;
        document.getElementById(modalId).classList.add(isVisible);
    });
    }

    return(
        <div className="ProductDatta">
            <ModalLogin/>
            <h3>{nombre}</h3>
            <h4>{subsection}</h4>
            <h2>$ {Intl.NumberFormat("de-DE",
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }).format(precio)
                        }
                </h2>
            <div className="divbuttons">
                <h4>Talle</h4>
                    <ButtonGroup className="mb-2">
                        {props.product.details.map((talle)=>(
                            <ToggleButton
                            id={talle.id}
                            type="checkbox"
                            variant="secondary"
                            checked={radioValue === talle.size}
                            value={talle.size}
                            disabled={talle.quantity === 0}
                            onChange={(e) => {
                                setChecked(e.currentTarget.checked)
                                setRadioValue(talle.id)
                                setTalle(talle.quantity)
                                setEstado(false)
                            }}
                          >
                            {talle.size}
                          </ToggleButton>
                        ))}
                    </ButtonGroup><br/>
                    <div className="Stock">{(checked===true)
                        ? (talle!==0) ? <>STOCK DISPONIBLE: {talle}</> : <>STOCK NO DISPONIBLE</>
                        : <></>}
                    </div>
                {(user!==undefined)
                    ? (buttonOutlet===1)
                        ? <><button className="button-carrito-mas" onClick={()=>comprar()}>AGREGAR AL CARRITO</button><br/></>
                        : <><button className="button-carrito-mas" style={{ pointerEvents: 'none' }}>AGREGAR AL CARRITO</button>
                            <br/>
                            <span style={{ color: 'red' }}>*No puede combinar productos de Outlet con productos de Linea</span>
                          </>
                    : <><button type="button" class="open-modal2" data-open="modal2">AGREGAR AL CARRITO</button><br/></>}
                {(estado)
                    ? <div className="Product-add-confirm">
                        <img className="tilde" src={tilde} alt=""/>
                        Agregado al carrito
                    </div>
                    : <></>
                }
                <div className="AlertProduct">{alerta}</div>
                {(user!==undefined)
                    ?   <button className="button-wish" onClick={()=>click()}>
                            {((like===true) ? <img className="like" src={white} alt=""/> : <img className="like" src={black} alt=""/>)}
                            WISHLIST
                        </button>
                    :   <button className="button-wish" data-open="modal2">
                            {((like===true) ? <img className="like" src={white} alt=""/> : <img className="like" src={black} alt=""/>)}
                            WISHLIST
                        </button>
                }
            </div>
            <div className="descript">
                <h4>Descripcion</h4>
                <h6>{description}</h6>
            </div>
        </div>
    )
}