import React from "react";
import {Link} from "react-router-dom";
import './user.css'

export default function NavUser(){
    return(
        <div>
            <div className="NavUser">
                <Link to="../Account"><button className="ButtonUser">MI CUENTA</button></Link>
                <Link to="../Account/Order"><button className="ButtonUser">MIS PEDIDOS</button></Link>
                <Link to="../Account/WishList"><button className="ButtonUser">MIS WISHLIST</button></Link>
                <Link to="../Account/Datta"><button className="ButtonUser">MI INFORMACION PERSONAL</button></Link>
            </div>
        </div>
    )
}