import React from "react";
import Cards from "./cards";
import './index.css'
import sendRequest from "../../conexion";
import {Link} from "react-router-dom";

import guiaCompra from "./icons/computadora.png"
import precio from "./icons/carritoCompras.png"
import medios from "./icons/tarjeta.png"
import envio from "./icons/envio.png"
import devoluciones from "./icons/devolucion.png"
import gift from "./icons/giftcard.png"
import contact from "./icons/contacto.png"

export default function AboutBody(){

    const gCompra={url:guiaCompra, texto:"GUIA COMPRA"}
    const pyp={url:precio, texto:"PRECIOS Y PROMOCIONES"}
    const myfpago={url:medios, texto:"MEDIOS Y FORMA DE PAGO"}
    const eyentrega={url:envio, texto:"ENVIOS Y ENTREGA"}
    const cydevolucion={url:devoluciones, texto:"CAMBIOS Y DEVOLUCIONES"}
    const gcards={url:gift, texto:"GIFT CARDS"}
    const contacto={url:contact, texto:"CONTACTO"}
    const vmayorista={ texto:"VENTA MAYORISTA"}
    const gtalles={ texto:"GUIA DE TALLES"}
    const qsomos={ texto:"QUIENES SOMOS"}

    return(
        <div>
            <div className="Contenedor-Cards">
                <Link className='link' to='/About/1'><Cards  params={gCompra}/></Link>
                <Link className='link' to='/About/2'><Cards  params={pyp}/></Link>
                <Link className='link' to='/About/3'><Cards  params={myfpago}/></Link>
                <Link className='link' to='/About/4'><Cards  params={eyentrega}/></Link>
            </div>
            <div className="Contenedor-Cards">
                <Link className='link' to='/About/5'><Cards  params={cydevolucion}/></Link>
                <Link className='link' to='/About/6'><Cards  params={gcards}/></Link>
                <Link className='link' to='/About/7'><Cards  params={contacto}/></Link>
                <Link className='link' to='/About/8'><Cards  params={vmayorista}/></Link>
            </div>
            <div className="Contenedor-Cards">
                <Link className='link' to='/About/9'><Cards  params={gtalles}/></Link>
                <Link className='link' to='/About/10'><Cards  params={qsomos}/></Link>
            </div>
        </div>
    )
}