import React from "react";
import Card from 'react-bootstrap/Card';
import "./product.css"

export default function Photos(props){
    const photo = props.photo

    const urlImagen = (i) =>{
        const foto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i}`
        return foto
    }

    return (
        <div className="ContenedorPhoto">
            <Card>
                <Card.Img className="ImagenPhoto" variant="top" src={urlImagen(photo.imagePath)} />
            </Card>
        </div>
    )
}