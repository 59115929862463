import React, { useState } from "react";
import { useUsuario } from "../../context/user";
import DetalleArticulos from "./detalleArticulos";
import {InputGroup,Button,Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import sendRequest from "../../conexion";

export default function Articulos (){
    const {cart,getCart} = useUsuario()
    const total = cart?.data?.cart?.totalMinPrice
    const token= localStorage.getItem('key')
    const [descuento,setDescuento]= useState('0')
    const [porcentaje,setPorcentaje]=useState('0')
    const direccion=`../Compra/Envio/${porcentaje}/${descuento}`

    const Descuento = async ()=>{
        try{
            const palabra = document.getElementById("descuento").value
            const id=cart.data.cart.id
            const desc = await sendRequest('GET', 
            `api/v1/order/searchDiscount/${token}/${id}/${palabra}`,
            null,
            {'Authorization': token})
            setTimeout(setDescuento((total*desc?.data?.data?.value)/100))
            setTimeout(setPorcentaje(desc?.data?.data?.id))
            console.log(desc)
        }catch (error){
            console.log(error)
        }
    }

    return(
        <div>
            <div className="tituloArticulo"><div className="CarritoTitle">MI CARRITO</div></div>
            <div className="TableArticulo">
                <table>
                    <tr>
                        <th className="titleTable">Articulo</th>
                        <th className="titleTable">Precio</th>
                        <th className="titleTable">Cantidad</th>
                        <th className="titleTable">Subtotal</th>
                        <th className="titleTable">Quitar</th>
                    </tr>
                        {cart?.data?.cartDetails?.map((info)=>(
                            <DetalleArticulos info={info} index={info.cartDetail.id} />
                        ))}
                </table>
            </div>
            <div className="ButtonArticulo">
                <div className="LeftArticulo">
                    <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="Introducir codigo de descuento"
                        aria-describedby="basic-addon2"
                        id="descuento"
                        />
                        <Button variant="secondary" id="button-addon2" onClick={()=>Descuento()}>
                        APLICAR DESCUENTO
                        </Button>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="Introducir codigo de GIFT CARD"
                        aria-describedby="basic-addon2"
                        />
                        <Button variant="secondary" id="button-addon2">
                        APLICAR GIFT CARD
                        </Button>
                    </InputGroup>
                </div>
                <div className="RightArticulo">
                    <div>RESUMEN</div>
                    <div className="TotalArticulo">
                        <div className="lefttable">Subtotal</div>
                        <div className="righttable">$ {Intl.NumberFormat("de-DE",
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }).format(total)
                        }
                        </div>
                    </div>
                    <Link to={direccion}><button className="CompraArticulo">COMPRAR</button></Link>
                </div>
            </div>
        </div>
    )
}