import React, { Fragment } from "react";
import Header from "../components/header";
import NavUser from "../components/User";
import Login from '../components/login'

import {useUsuario} from '../context/user'
import BodyOrder from "../components/User/cuenta/order";


function Order () {
    const {user} = useUsuario()

    return (
        <Fragment>
            {(user!==undefined)
                ?   <div>
                        <NavUser/>
                        <BodyOrder/>
                    </div>
                :   <div>
                        <Login/>

                    </div>
                }

        </Fragment>
    )
}

export default Order