import React from "react";
import '../components/User/cuenta/cuenta.css'
import CambioDirEnvio from "../components/User/edicion-cuenta/info-dirEnvio"
import Header from "../components/header";
import {Link} from "react-router-dom";


export default function DirEnvioCambio (){
    return(
        <div>
            <Header/>
            <div className="Titulo-Dir">
                <h2>CAMBIAR DIRECCION DE ENVIO</h2>
            </div>
            <CambioDirEnvio/>
            <div className="Cerrar-Dir">
                <Link to="/Account"><button className="Cerrar-Dir-Button">cerrar</button></Link>
            </div>
        </div>
    )
}