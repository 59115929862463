import React from "react";
import "../components/User/cuenta/cuenta.css";
import InfoContacto from "../components/User/edicion-cuenta/info-contacto";
import Header from "../components/header";
import { Link } from "react-router-dom";

export default function DirEnvioCambio() {
  return (
    <div>
      <h2>Datos personales</h2>
      <InfoContacto />
    </div>
  );
}
