import React from "react";
import CrearDirEnvio from "../components/User/edicion-cuenta/crear-direnvio";
import "../components/User/cuenta/cuenta.css";
import Header from "../components/header";
import { Link } from "react-router-dom";

export default function DirEnvioNueva() {
  return (
    <div>
      <h2>Dirección de envío</h2>
      <CrearDirEnvio />
    </div>
  );
}
