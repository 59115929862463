import React from "react";
import { useEffect, useState } from "react";
import sendRequest from "../../conexion";
import {CardGroup} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../cards/cards.css'
import Cards from "../cards/cards";
import {useParams} from "react-router-dom"
import './search.css'
import { useDominio } from "../../context/dominio";


const MohaveBody = () => {
    const {search} = useParams()
    const [imagenes, SetImagen]= useState([])
    const {dominio} = useDominio()
    const [marca,setMarca] = useState()

    const urlImagen = (i) =>{
        const primeraFoto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`
        return primeraFoto
    }

    const session = (i) =>{
        const primeraFoto = `${i[0]}`
        return primeraFoto
    }

    useEffect(()=>{
        buscar()
        if(dominio){
            const productos = imagenes.filter((img)=>img.product.idMarca===2)
            setMarca(productos)
        } else {
            const productos = imagenes.filter((img)=>img.product.idMarca===3)
            setMarca(productos)
        }
    },[imagenes])

    const buscar = async (event)=>{
        let imagen = ''
        try{
            (document.getElementById("palabra").value==='')
                ? imagen = await sendRequest('GET', `/api/v1/allActiveProducts`, null)
                : imagen = await sendRequest('GET', `/api/v1/allActiveProducts?q=${event.target.value}`, null)
            
            const image = imagen.data.data
            SetImagen(image)
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <div className="Cont-Cards-TA">
            <div className="InSearch">
                <input className="input-search" id="palabra" placeholder="Buscar... " onChange={buscar}></input>
                <button className="buttonSearch" id="Search" onClick={()=>buscar()}></button>
            </div>
            <CardGroup>
                {marca?.length>0 ?
                marca.map((img) => (
                        <Cards
                            id={img.product.id}
                            name={img.product.name}
                            url={urlImagen(img.productPhotos.map((i)=>(i.imagePath)))}
                            session={session(img.subsections.map((i)=>(i.name)))}
                            precio={img.product.priceMin}
                        />
                ))
                 : <div className="ResultadoVacio mt-3">No hay resultados</div>}
            </CardGroup>
        </div>
    )
}

export default MohaveBody