import React from "react";
import "./leftMenu.css";
import { Nav } from "react-bootstrap";

export default function LeftMenu() {
  return (
    <div className="MenuIzquierdo">
      <Nav className="flex-column">
        <Nav.Link className="Link" href="/New">
          NEW
        </Nav.Link>
        <Nav.Link className="Link" href="/Mohave">
          MOHAVE
        </Nav.Link>
        <Nav.Link className="Link" href="/Gell">
          GELL
        </Nav.Link>
        <Nav.Link className="Link" href="/Tiro-Alto">
          TIRO ALTO
        </Nav.Link>
        <Nav.Link className="Link" href="/Tiro-Medio">
          TIRO MEDIO
        </Nav.Link>
        <Nav.Link className="Link" href="/Fallados">
          FALLADOS
        </Nav.Link>
        <Nav.Link className="Link" href="/Sale">
          SALE
        </Nav.Link>
      </Nav>
    </div>
  );
}
