import React from "react";
import Header from "../../../components/header";

const Topbar = ({ toggleSidebar }) => {
  return (
    <div
      color="light"
      light
      className="shadow-sm p-3 mb-5 bg-white rounded"
      expand="md"
    >
      <Header toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Topbar;
