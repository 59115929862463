import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useUsuario } from "../../../context/user";
import "./cuenta.css";
import sendRequest from "../../../conexion";
import { Link } from "react-router-dom";

export default function DireccionPago() {
  const [validated, setValidated] = useState(false);
  const { direccionPago, dirPago } = useUsuario();
  const token = localStorage.getItem("key");
  const [error, setError] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
  };

  const send = async () => {
    const name = document.getElementById("nombre").value;
    const phone = document.getElementById("phone").value;
    const dni = document.getElementById("dni").value;
    const country = document.getElementById("country").value;
    const province = document.getElementById("province").value;
    const city = document.getElementById("city").value;
    const postal_code = document.getElementById("postalCode").value;
    const street_name = document.getElementById("streetName").value;
    const number = document.getElementById("nro").value;
    const floor = document.getElementById("floor").value;
    const department = document.getElementById("department").value;
    try {
      await sendRequest(
        "POST",
        "api/v1/payment-address",
        {
          name,
          phone,
          dni,
          country,
          province,
          city,
          postal_code,
          street_name,
          floor,
          department,
          number,
        },
        { Authorization: token }
      );
      direccionPago(token);
      window.location.href="/Account";
    } catch (error) {
      console.log(error);
      setError(true)
    }
  };

  return (
    <div className="Cuenta-body">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ textAlign: "start" }}
      >
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="nombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nombre"
              defaultValue=""
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="phone">
            <Form.Label>Telefono</Form.Label>
            <Form.Control
              type="number"
              placeholder="Teléfono"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un teléfono valido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="dni">
            <Form.Label>DNI</Form.Label>
            <Form.Control
              type="number"
              placeholder="DNI"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un DNI valido.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="country">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              placeholder="País"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un país válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="province">
            <Form.Label>Cuidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ciudad"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una ciudad válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="city">
            <Form.Label>Localidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Localidad"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una localidad válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="postalCode" className="mt-3">
            <Form.Label>Código postal</Form.Label>
            <Form.Control
              type="number"
              placeholder="Código postal"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un código postal válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="streetName" className="mt-3">
            <Form.Label>Calle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Calle"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una calle válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="nro" className="mt-3">
            <Form.Label>Número</Form.Label>
            <Form.Control
              type="number"
              placeholder="Número"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un número de calle válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="floor" className="mt-3">
            <Form.Label>Piso</Form.Label>
            <Form.Control
              type="number"
              placeholder="Piso"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un piso válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="department" className="mt-3">
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Departamento"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un departamento válido.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-5 justify-content-end">
          <Button
            onClick={send}
            variant="success"
            className="button-user"
            style={{ width: "150px" }}
          >
            Crear
          </Button>
          <Link
            to="/Account"
            style={{ textDecoration: "none", width: "150px" }}
          >
            <Button className="button-user" style={{ width: "150px" }}>
              Cancelar
            </Button>
          </Link>
          <div className="cont-label-error">
            {(error)?<label id="label-error">Cargar todos los campos</label>:<></>}
          </div>
        </Row>
      </Form>
    </div>
  );
}
