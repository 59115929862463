import React, {useState,useEffect} from "react";
import { useUsuario } from "../../context/user";
import './modal-login.css'

export default function ModalLogin (){
    const {login,user} = useUsuario()
    const [error,setError]=useState("")
    const isVisible = "is-visible";
    const closeEls = document.querySelectorAll("[data-close]");
    const token = localStorage.getItem('key')
    const [close,setClose]=useState()
    const [estado,setEstado]=useState('pendiente')

    const Ingresar = ()=>{
        const email = document.getElementById('Email').value
        const password= document.getElementById('Password').value
        setClose(false)
        login(email,password)
            .then(res=>{
                (res===true) ? setEstado("Ingresado") : setEstado("Rechazado")
            })
    }
  
    document.addEventListener("keyup", function(event) {
        if (event.keyCode === 13) {
            Ingresar()
        }
    });

    for (const el of closeEls) {
    el.addEventListener("click", function() {
        this.parentElement.parentElement.parentElement.classList.remove(isVisible);
    });
    }

    useEffect(()=>{
        if(estado==="Ingresado"){
            if (user && close===false){
                setClose(true)
                window.location.reload(true)
            }
        } else {
            if (estado==="Rechazado"){
                setError("Usuario o contraseña incorrectos")
                console.log(estado)
            }
        }
    })

    return(
        <div class="modalLogin" id="modal2">
            <div class="modalLogin-dialog">
                <header class="modalLogin-header">
                    <button class="modalLogin-modal" aria-label="close modal" data-close>X</button>
                </header>
                <section class="modalLogin-content">
                    <div className="mod-log-body">
                        <div className="mod-log-izq">
                            <h5>REGISTRARTE</h5>
                            <div>Creando una cuenta podes armar tu wishlist, 
                                realizar una compra y ver tu historial de pedidos
                            </div>
                        </div>
                        <div className="mod-log-der">
                            <h5>CLIENTES REGISTRADOS</h5>
                            <div>
                                <div className="mod-log-contlabel">
                                    <a className="mod-log-label">Email</a>
                                    <input id="Email" type="email"></input>
                                </div>
                                <div className="mod-log-contlabel">
                                    <a className="mod-log-label">Password</a>
                                    <input id="Password" type="password"></input>
                                </div>
                                <div className="Error">{error}</div>
                            </div>
                        </div>
                    </div>
                    <div className="mod-log-but">
                        <div className="mod-log-but-cont">
                            <button className="mod-log-button"
                                onClick={()=>{document.location.href = `../Account/Register`}}
                            >CREAR CUENTA</button>
                        </div>
                        <div className="mod-log-but-cont">
                            <button className="mod-log-button" onClick={Ingresar}>INICIAR SESION</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}