import React, { useEffect, useState } from "react";
import "./compras.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useUsuario } from "../../context/user";
import { useNavigate, useParams } from "react-router-dom";
import ModalDireccion from "./modalDireccion";
import sendRequest from "../../conexion";
import { Button } from "react-bootstrap";

export default function DireccionEnvioPago() {
  const { user, cart, dirPago } = useUsuario();
  const cantidad = cart?.data?.cartDetails?.length;
  const cuerpo = cart?.data?.cartDetails;
  const [valor, setValor] = useState(12);
  const { Descuento, Porcentaje } = useParams();
  const [envio, setEnvio] = useState(dirPago);
  const token = localStorage.getItem("key");
  const [shipments, setShipments] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setEnvio(dirPago);

    const fetchData = async () => {
      try {
        const shipmentResponse = await sendRequest(
          "GET",
          "/api/v1/order/getShipments",
          {},
          { Authorization: token }
        );

        if (shipmentResponse?.data) {
          const activeShipments = shipmentResponse.data.shipment
            .filter((shipment) => shipment.active)
            .sort((a, b) => a.id - b.id);
          setShipments(activeShipments);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dirPago]);

  const Envio = () => {
    if (dirPago.data.length !== 0) {
      window.location.href = `../../../Compra/Pago/${Porcentaje}/${Descuento}/${valor}`;
    } else {
      setMsg(`* Cargar Dirección de pago`);
    }
  };

  const openEls = document.querySelectorAll("[data-open]");
  const isVisible = "is-visible";
  for (const el of openEls) {
    el.addEventListener("click", function () {
      const modalId = this.dataset.open;
      document.getElementById(modalId).classList.add(isVisible);
    });
  }

  document.addEventListener("click", (e) => {
    if (e.target === document.getElementById("modal1")) {
      document.getElementById("modal1").classList.remove(isVisible);
      console.log(`entro`);
    }
  });

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      document.getElementById("modal1").classList.remove(isVisible);
    }
  });

  const goToAccount = () => {
    navigate("/Account");
  };

  return (
    <div>
      <ModalDireccion />
      <div className="top">
        <div className="DEPLeft">
          <div className="Left-Titulo">
            {" "}
            Envio &emsp;&emsp;&emsp;&emsp; Pago
          </div>
          <div className="Left-Titulo">
            <ProgressBar now={80} />
          </div>
          <div className="Left-Subtitulo">
            <div className="subtitulo-Body">DIRECCION DE ENVIO</div>
          </div>
          <div className="Left-datos">
            Nombre: {user?.name} {user?.surname}&emsp;&emsp; DNI: {user?.dni}
            <br />
            Tel: {user?.phone}
            <br />
            Direccion de Facturación: {envio?.data[0]?.street_name}{" "}
            {envio?.data[0]?.number}
            {envio?.data[0]?.floor != null ? (
              <> Piso: {envio?.data[0]?.floor}</>
            ) : (
              <></>
            )}
            {envio?.data[0]?.department != null ? (
              <> Depto: {envio?.data[0]?.department}</>
            ) : (
              <></>
            )}
            <br />
            {envio?.data[0]?.city}, {envio?.data[0]?.country}
            <div />
          </div>
        </div>
        <div className="DEPRight">
          <div className="Right-Title">Resumen de tu Pedido</div>
          <div className="Right-Title">
            {cantidad} Articulos en el carrito de compras{" "}
          </div>
          <div className="Right-body">
            {cuerpo?.map((info) => (
              <div className="TablaDetalle">
                <div className="Table-Body">
                  <div className="lefttable">
                    {info.product.name}
                    <br />
                    Talle: {info.productDetail.map((i) => i.size)}
                    <br />
                  </div>
                  <div className="righttable">
                    ${" "}
                    {Intl.NumberFormat("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(info.product.priceMin)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div></div>
      </div>
      <div className="DEPButton">
        <button
          className="mt-3 mb-3 button-cart"
          style={{ maxWidth: "200px" }}
          onClick={goToAccount}
        >
          + Direcciones de pago
        </button>
        <div>MÉTODOS DE ENVIO</div>
        {shipments.length &&
          shipments.map((shipment) => (
            <div onChange={(event) => setValor(shipment.id)}>
              <div className="MetEnvio">
                <input type="radio" name="envio" value="9" />
                <div className="PreEnvio">{shipment.cost}</div>
                <div className="LugarEnvio">{shipment.name}</div>
              </div>
            </div>
          ))}
        <div className="button-end">
          <button className="Siguiente" onClick={() => Envio()}>
            SIGUIENTE
          </button>
        </div>
        <div className="error-msg">{msg}</div>
      </div>
    </div>
  );
}
