import React from "react"
import {Card} from 'react-bootstrap'
import {Link} from "react-router-dom";
import "./cards.css"

export default function Cards(props){
    const id = props.id
    const url = props.url
    const name = props.name
    const session = props.session
    const precio = `$ ${props. precio}`
    const direction = `/product/${id}`

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
            <Card key={id}>
                <Link className='link' to={direction}>
                    <Card.Body>
                        <Card.Img className="Imagen" variant="top" src={url} />
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>{session}</Card.Text>
                        <Card.Text>
                            {precio}
                        </Card.Text>
                    </Card.Body>
                </Link>
            </Card>
        </div>
    )
}