import React from "react";
import './index.css'

export default function Cards(data){
    const label= data.params.texto
    const url=data.params.url
    return(
        <div className="Card">
            <div className="ImageCard">
            {url ? (
            <img
                alt=''
                src= {url}
                onerror="this.style.display='none'"
                className="card-image"
                width="60px"
                height="60px"
            ></img>
            ) : null}
            </div>
            <div className="TextCard">
                {label}
            </div>
        </div>
    )
}