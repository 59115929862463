import React, { useState } from "react";
import { Form } from "react-bootstrap";
import sendRequest from "../../../conexion";
import { useUsuario } from "../../../context/user";

export default function Datta() {
  const { user, cargarUser } = useUsuario();
  const [nombre, setNombre] = useState(user.name);
  const [apellido, setApellido] = useState(user.surname);
  const token = localStorage.getItem("key");

  const send = async () => {
    const name = document.getElementById("nombredatta").value;
    const surname = document.getElementById("Apellidodatta").value;
    const phone = user.phone;
    const dni = user.dni;
    const country = user.country;
    const province = user.province;
    const city = user.city;
    const postalCode = user.postalCode;
    const streetName = user.streetName;
    const nro = user.nro;
    try {
      await sendRequest(
        "PUT",
        "api/v1/user",
        {
          name,
          surname,
          phone,
          dni,
          country,
          province,
          city,
          postalCode,
          streetName,
          nro,
        },
        { Authorization: token }
      );
      cargarUser(token);
      await sendRequest("POST", "/api/v1/user/changePassword");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form>
      <div className="datta-cuerpo">MI INFORMACIÓN PERSONAL</div>
      <div className="body-datta">
        <div className="datta-left">
          <div className="data-box">
            <div>
              Nombre<label className="obligatorio">*</label>
            </div>
            <Form.Control
              id="nombredatta"
              type="text"
              placeholder="Nombre"
              onChange={(e) => setNombre(e.target.value)}
              defaultValue=""
              value={nombre}
              required
            />
          </div>
          <div className="data-box">
            <div>
              Apellido<label className="obligatorio">*</label>
            </div>
            <Form.Control
              id="Apellidodatta"
              type="text"
              placeholder="Nombre"
              onChange={(e) => setApellido(e.target.value)}
              defaultValue=""
              value={apellido}
              required
            />
          </div>
          <div className="data-box">
            <div>
              Email<label className="obligatorio">*</label>
            </div>
            <Form.Control
              type="text"
              placeholder="Email"
              defaultValue=""
              value={user.email}
              required
            />
          </div>
          <div className="data-box">
            <div>
              Contraseña<label className="obligatorio">*</label>
            </div>
            <Form.Control
              type="password"
              placeholder="Contraseña"
              defaultValue=""
              required
            />
          </div>
          <button
            className="Guardar-datta"
            onClick={() => {
              send();
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
}
