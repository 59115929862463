import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useUsuario } from "../../../context/user";
import "./cuenta.css";
import sendRequest from "../../../conexion";
import { Link } from "react-router-dom";

export default function DireccionPago() {
  const [validated, setValidated] = useState(false);
  const { dirPago, direccionPago } = useUsuario();
  const token = localStorage.getItem("key");

  const [idPago, setID] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dni, setDni] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [postal_code, setCode] = useState("");
  const [street_name, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [floor, setFloor] = useState("");
  const [department, setDepartment] = useState("");

  useEffect(() => {
    if (dirPago !== undefined) {
      setID(dirPago.data[0].id);
      setName(dirPago.data[0].name);
      setPhone(dirPago.data[0].phone);
      setDni(dirPago.data[0].dni);
      setCountry(dirPago.data[0].country);
      setProvince(dirPago.data[0].province);
      setCity(dirPago.data[0].city);
      setCode(dirPago.data[0].postal_code);
      setStreet(dirPago.data[0].street_name);
      setNumber(dirPago.data[0].number);
      setFloor(dirPago.data[0].floor);
      setDepartment(dirPago.data[0].department);
    }
  }, [dirPago]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
  };

  const send = async () => {
    const name = document.getElementById("nombrePago").value;
    const phone = document.getElementById("phonePago").value;
    const dni = document.getElementById("dniPago").value;
    const country = document.getElementById("countryPago").value;
    const province = document.getElementById("provincePago").value;
    const city = document.getElementById("cityPago").value;
    const postal_code = document.getElementById("postalCodePago").value;
    const street_name = document.getElementById("streetNamePago").value;
    const number = document.getElementById("nroPago").value;
    const floor = document.getElementById("floor").value;
    const department = document.getElementById("department").value;
    try {
      await sendRequest(
        "PUT",
        `api/v1/payment-address/${idPago}`,
        {
          name,
          phone,
          dni,
          country,
          province,
          city,
          postal_code,
          street_name,
          floor,
          department,
          number,
        },
        { Authorization: token }
      );
      direccionPago(token);
      window.location.href = "/Account";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Cuenta-body">
      <Form noValidate onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group className="mt-3" as={Col} md="4" controlId="nombrePago">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre"
              required
              defaultValue={name}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="phonePago">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="number"
              placeholder="Solo números"
              required
              defaultValue={phone}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un teléfono válido.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="dniPago">
            <Form.Label>DNI</Form.Label>
            <Form.Control
              type="number"
              placeholder="Solo números"
              required
              defaultValue={dni}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un DNI válido.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="countryPago">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              placeholder="País"
              required
              defaultValue={country}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un país válido.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="provincePago">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ciudad"
              required
              defaultValue={province}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una ciudad válida.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="cityPago">
            <Form.Label>Localidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Localidad"
              required
              defaultValue={city}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una localidad válida.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="postalCodePago">
            <Form.Label>Código Postal</Form.Label>
            <Form.Control
              type="number"
              placeholder="Código Postal"
              required
              defaultValue={postal_code}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un código postal válido.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="streetNamePago">
            <Form.Label>Calle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de la calle"
              required
              defaultValue={street_name}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una calle válida.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="nroPago">
            <Form.Label>Altura</Form.Label>
            <Form.Control
              type="number"
              placeholder="Número de la calle"
              required
              defaultValue={number}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una altura de calle válida.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="floor">
            <Form.Label>Piso</Form.Label>
            <Form.Control
              type="number"
              placeholder="Número de piso"
              defaultValue={floor}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un piso válido.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} md="4" controlId="department">
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Número del departamento"
              defaultValue={department}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un departamento válido.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mt-5 justify-content-end">
          <Button
            onClick={send}
            variant="success"
            className="button-user"
            style={{ width: "150px" }}
          >
            Actualizar
          </Button>
          <Link
            to="/Account"
            style={{ textDecoration: "none", width: "150px" }}
          >
            <Button className="button-user" style={{ width: "150px" }}>
              Cancelar
            </Button>
          </Link>
        </Row>
      </Form>
    </div>
  );
}
