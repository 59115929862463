import React, { Fragment } from "react";
import './cuenta.css'
import { useUsuario } from "../../../context/user";
import sendRequest from "../../../conexion";

export default function Cuenta (){
    const {user, dirPago, dirEnvio,logout,direccionEnvio,direccionPago} = useUsuario()
    const token = localStorage.getItem('key')

    const cerrar = ()=>{
        logout()
        localStorage.clear()
        window.location.href=`/`
    }

    const CrearEnvio = ()=>{
        window.location.href=`/Account/DirEnvio/Nuevo`
    }

    const EditarEnvio = ()=>{
        window.location.href=`../Account/DirEnvio/Cambio`
    }

    const eliminarDirEnvio = async (key)=>{
        try{
            await sendRequest('DELETE', `api/v1/address/${dirEnvio.data[0].id}`,{},{'Authorization': token})
            direccionEnvio(token)
        } catch (error) {
            console.log(error)
        }
    }

    const EditarPago = ()=>{
        window.location.href=`/Account/DirPago/Cambio`
    }

    const CrearPago = ()=>{
        window.location.href=`/Account/DirPago/Nuevo`
    }

    const eliminarDirPago = async ()=>{
        try{
            await sendRequest('DELETE', `api/v1/payment-address/${dirPago.data[0].id}`,{},{'Authorization': token})
            direccionPago(token)
        } catch (error) {
            console.log(error)
        }
    }

    const limpiar = async () => {
        try{
            const email=user.email
            const envio = await sendRequest('POST', 'api/v1/user/reset',{email})
            console.log(envio.data.status)
            if (envio.data.status==="success"){
                alert(`se envio el mail para restablecer la contraseña`)
            }
        } catch (error){
            console.log(error)
        }
    }

    const CambioContacto = ()=>{
        window.location.href=`/Account/Cambio`
    }

    return (
        <div className="PrincipalCuenta">
            <div>
                MI INFORMACION PERSONAL
                <div className="contenedor-user">
                    <div className="izquierda-user">
                        <div className="titulo-user">Información de contacto</div>
                        <div className="info-user">
                            {user.name}<br/>
                            {user.email}<br/>
                            <br/>
                        </div>
                        <div className="button-contenedor-user">
                            <button className="button-user" onClick={CambioContacto}>
                                Editar
                            </button>
                            <button className="button-user" onClick={()=>limpiar()}>
                                Resetear Contraseña
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    MIS DIRECCIONES
                    <div className="contenedor-user">
                    {(dirPago===undefined)? null
                        :
                        <div className="izquierda-user">
                            <div className="titulo-user">Dirección de pago predeterminada</div>
                            {(dirPago?.data.length!==0) ?
                            (dirPago.data.map((info)=>(
                            <>
                                <div className="info-user">
                                    Nombre:{info.name}<br/>
                                    DNI:{info.dni}<br/>
                                    Tel. {info.phone}<br/>
                                    Dirección: {info.street_name} {info.number} {info.floor} {info.department}<br/>
                                    Provincia: {info.city}<br/>
                                    Localidad: {info.country}<br/>
                                    CCPP: {info.postal_code}<br/>
                                    <br/>
                                </div>
                                <div className="button-contenedor-user">
                                <button className="button-user" onClick={EditarPago}>
                                    Editar
                                </button>
                                <button className="button-user" onClick={eliminarDirPago}>
                                    Eliminar
                                </button>
                                </div>
                            </>
                            ))) : <><br/><br/><br/><br/><br/><br/><br/><br/>
                                <div className="button-contenedor-user">
                                    <button className="button-user" onClick={CrearPago}>
                                        Crear
                                    </button>
                                </div>
                            </>
                            }
                        </div>
                    }
                    {(dirEnvio===undefined)? null
                    :
                        <div className="derecha-user">
                            <div className="titulo-user">Dirección de envío predeterminada</div>
                            <div>
                            {(dirEnvio?.data.length!==0) ?
                            (dirEnvio.data.map((info)=>(
                                <>
                                    <div className="info-user">
                                        Dirección: {info.street_name}<br/>
                                        Provincia: {info.city}<br/>
                                        Localidad: {info.country}<br/>
                                        CCPP: {info.postal_code}<br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                    <div className="button-contenedor-user">
                                        <button className="button-user" onClick={EditarEnvio}>
                                            Editar
                                        </button>
                                        <button className="button-user" onClick={eliminarDirEnvio}>
                                            Eliminar
                                        </button>
                                    </div>
                                </>
                            ))) : <><br/><br/><br/><br/><br/><br/><br/><br/>
                                <div className="button-contenedor-user">
                                    <button className="button-user" onClick={CrearEnvio}>
                                        Crear
                                    </button>
                                </div>
                            </>
                            }
                        </div>
                        </div>

                    }
                    </div>
                    <button className="OutSession" onClick={()=>cerrar()}>Cerrar Sesion</button>
                </div>

           </div>
        </div>
    )
}