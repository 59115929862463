import React, {Fragment} from "react";
import Header from "../components/header/index";
import AboutCard from "../components/about/aboutCard";

export default function About(){
    return(
        <Fragment>
        <div className="body">
            <div className="Header-About">
                INFORMACION
            </div>
            <div className="Body-About">
               <AboutCard/>
            </div>
            <div className="Footer-About">
            </div>
        </div>
        </Fragment>
    )
}