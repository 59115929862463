import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useUsuario } from "../../../context/user";
import "./cuenta.css";
import sendRequest from "../../../conexion";
import { Link } from "react-router-dom";

export default function DireccionPago() {
  const [validated, setValidated] = useState(false);
  const { direccionEnvio } = useUsuario();
  const token = localStorage.getItem("key");

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
  };

  const send = async () => {
    const country = document.getElementById("country").value;
    const province = document.getElementById("province").value;
    const city = document.getElementById("city").value;
    const postal_code = document.getElementById("postalCode").value;
    const street_name = document.getElementById("streetName").value;
    const number = document.getElementById("nro").value;
    const floor = document.getElementById("floor").value;
    const department = document.getElementById("department").value;
    try {
      await sendRequest(
        "POST",
        "api/v1/address",
        {
          country,
          province,
          city,
          postal_code,
          street_name,
          floor,
          department,
          number,
        },
        { Authorization: token }
      );
      direccionEnvio(token);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Cuenta-body">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ textAlign: "start" }}
      >
        <Row className="mb-3">
          <Form.Group as={Col} md="3" controlId="country">
            <Form.Label>Pais</Form.Label>
            <Form.Control
              type="text"
              placeholder="Pais"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un Pais valido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="province">
            <Form.Label>Cuidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Cuidad"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una cuidad valida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="city">
            <Form.Label>Localidad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Localidad"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una localidad valida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="postalCode">
            <Form.Label>Código postal</Form.Label>
            <Form.Control
              type="number"
              placeholder="Código postal"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un código postal válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="streetName" className="mt-3">
            <Form.Label>Calle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Calle"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una calle válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="nro" className="mt-3">
            <Form.Label>Número</Form.Label>
            <Form.Control
              type="number"
              placeholder="Número"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un número válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="floor" className="mt-3">
            <Form.Label>Piso</Form.Label>
            <Form.Control
              type="number"
              placeholder="Piso"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un piso válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="department" className="mt-3">
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Departamento"
              defaultValue=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una departamento válido.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-5 justify-content-end">
          <Button
            onClick={send}
            variant="success"
            className="button-user"
            style={{width: '150px'}}
          >
            Actualizar
          </Button>
          <Link
            to="/Account"
            style={{ textDecoration: "none", width: "150px" }}
          >
            <Button className="button-user" style={{ width: "150px" }}>
              Cancelar
            </Button>
          </Link>
        </Row>
      </Form>
    </div>
  );
}
