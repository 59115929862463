import React from "react";
import { Routes, Route } from "react-router-dom";
import Maps from "../view/Maps";
import About from "../view/About";
import Abouts from "../view/Abouts";
import TiroAlto from "../view/TiroAlto";
import TiroMedio from "../view/TiroMedio";
import Gell from "../view/Gell";
import Mohave from "../view/Mohave";
import Home from "../view/Home";
import Products from "../view/Products";
import Sale from "../view/Sale";
import Fallados from "../view/Fallados";
import Search from "../view/Search";
import Account from "../view/User";
import Registrarse from "../view/Registrarse";
import Wishlist from "../view/Wishlist";
import Datta from "../view/UserDatta";
import CompraEnvio from "../view/CompraEnvio";
import CompraArticulos from "../view/CompraArticulos";
import CompraPago from "../view/CompraPago";
import Order from "../view/UserOrser";
import DirEnvioNueva from "../view/UserDirEnvioNueva";
import DirEnvioCambio from "../view/UserDirEnvioCambio";
import DirPagoNueva from "../view/UserDirPagoNueva";
import DirPagoCambio from "../view/UserDirPagoCambio";
import UserCambio from "../view/UserCambio";

function Rutas() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Maps" element={<Maps />} />
        <Route path="/About" element={<About />} />
        <Route path="/About/:id" element={<Abouts />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/Account/Cambio" element={<UserCambio />} />
        <Route path="/Account/WishList" element={<Wishlist />} />
        <Route path="/Account/Datta" element={<Datta />} />
        <Route path="/Account/Register" element={<Registrarse />} />
        <Route path="/Account/Order" element={<Order />} />
        <Route path="/Account/DirPago/Nuevo" element={<DirPagoNueva />} />
        <Route path="/Account/DirPago/Cambio" element={<DirPagoCambio />} />
        <Route path="/Account/DirEnvio/Nuevo" element={<DirEnvioNueva />} />
        <Route path="/Account/DirEnvio/Cambio" element={<DirEnvioCambio />} />
        <Route path="/New" element={<Home />} />
        <Route path="/Mohave" element={<Mohave />} />
        <Route path="/Gell" element={<Gell />} />
        <Route path="/Tiro-Alto" element={<TiroAlto />} />
        <Route path="/Tiro-Medio" element={<TiroMedio />} />
        <Route path="/Fallados" element={<Fallados />} />
        <Route path="/Sale" element={<Sale />} />
        <Route path="/product/:id" element={<Products />} />
        <Route path="/search" element={<Search />} />
        <Route
          path="/Compra/Envio/:Porcentaje/:Descuento"
          element={<CompraEnvio />}
        />
        <Route path="/Compra/Articulos" element={<CompraArticulos />} />
        <Route
          path="/Compra/Pago/:Porcentaje/:Descuento/:Envio"
          element={<CompraPago />}
        />
      </Routes>
  );
}

export default Rutas;
