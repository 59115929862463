import React, {useState} from "react";
import sendRequest from "../../conexion";
import { useUsuario } from "../../context/user";

export default function Detalle(props){
    const {getCart} = useUsuario()
    const info= props.info
    let [cantidad,setCantidad] = useState(info.cartDetail.quantity)
    const subtotal = cantidad*info.product.priceMin
    const lista = props.index
    const token = localStorage.getItem('key')
    
    const urlImagen = (i) =>{
        return `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}` 
    }

    const quitar = async (idProduct)=>{
        if (cantidad > 1) {
          setCantidad(--cantidad)
          const body={
              idProductDetail: `${idProduct}`,
              quantity: `${cantidad}`
            }
          try{
            await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/0`,body,{'Authorization': token})
          } catch (error) {
            console.log(error)
          }
          getCart(token)
        }
    }

    const sumar = async (idProduct)=>{
      setCantidad(++cantidad)
      const body={
          idProductDetail: `${idProduct}`,
          quantity: `${cantidad}`
        }
      try{
        await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/0`,body,{'Authorization': token})
      } catch (error) {
        console.log(error)
      }
      getCart(token)
    }

    const borrar = async ()=>{
        try{
          await sendRequest('POST', `api/v1/cart/deleteItem/${lista}/0`,null,{'Authorization': token})
          getCart(token)
        } catch (error) {
          console.log(error)
        }
      }

    return(
        <tr>
            <td>
                <td>
                    <img className="ImgCart"
                        src={urlImagen(info.productPhotos.map((img)=>(img.imagePath)))}
                        alt=""
                    ></img>
                </td>
                <td>
                    {info.product.name}<br/>
                    Talle: {info.productDetail.map((i)=>(i.size))}
                </td>
            </td>
            <td>${Intl.NumberFormat("de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(info.product.priceMin)
                }
              </td>
            <td>
                <button className="QualityBoton" onClick={()=>quitar(info.cartDetail.idProductDetail)}>-</button>
                {cantidad}
                <button className="QualityBoton" onClick={()=>sumar(info.cartDetail.idProductDetail)}>+</button>
            </td>
            <td>${Intl.NumberFormat("de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(subtotal)
                }
              </td>
            <td><button onClick={()=>borrar()}>X</button></td>
        </tr>
    )
}