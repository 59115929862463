import React from "react";
import { Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

function Markers({location}) {
  const icon = new Icon({
    iconUrl: require("./prueba.png"),
    iconRetinaUrl: require("./prueba.png"),
    iconAnchor: null,
    shadowAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    iconSize: [35, 35],
    className: "Leaflet-venue-icon",
  });

  return (
    <Marker position={[location.latitude, location.longitude]} icon={icon}>
      <Popup>{location.addresss}</Popup>
    </Marker>
  );
}

export default Markers;
