import React from "react";
import './modal.css'
import { useUsuario } from "../../context/user";
import ChangeDireccion from "../User/edicion-cuenta/info-dirPago"
import AddDireccion from "../User/edicion-cuenta/crear-dirpago"

export default function ModalDireccion (){
    const {dirPago,direccionPago} = useUsuario()

    const isVisible = "is-visible";
    const closeEls = document.querySelectorAll("[data-close]");
    for (const el of closeEls) {
    el.addEventListener("click", function() {
        this.parentElement.parentElement.parentElement.classList.remove(isVisible);
    });
    }

    return(
        <div class="modalDireccion" id="modal1">
            <div class="modalDireccion-dialog">
                <header class="modalDireccion-header">
                    DIRECCION DE PAGO
                </header>
                <section class="modalDireccion-content">
                    {(dirPago.data.length!==0)?<ChangeDireccion/>:<AddDireccion/>}
                </section>
            </div>
        </div>
    )
}