import React from "react";
import { RotatingLines } from  'react-loader-spinner';
import './loader.css'

export default function Loader(){
    return (
        <div className="Loader-Contenedor">
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </div> 
    )
}