import React, { Fragment } from "react";
import Header from "../components/header";
import TiroAltoBody from "../components/tiroMedio";

function TiroAlto () {
    return (
        <Fragment>
            <TiroAltoBody/>
        </Fragment>
    )
}

export default TiroAlto