import React, {useState} from "react";
import sendRequest from "../../conexion";
import { useUsuario } from "../../context/user";

export default function ArticuloPago(props){
    const {getCart} = useUsuario()
    const info= props.info
    let [cantidad,setCantidad] = useState(info.cartDetail.quantity)
    const subtotal = cantidad*info.product.priceMin
    const lista = props.index
    const token = localStorage.getItem('key')

    return(
        <div className="ArticulosPago">
            <div className="CanArtPago">
                {cantidad}  
            </div>
            <div className="InfArtPago">
                {info.product.name}<br/>
                Talle: {info.productDetail.map((i)=>(i.size))}
            </div>
            <div>${Intl.NumberFormat("de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(subtotal)
                }
            </div>
        </div>
    )
}