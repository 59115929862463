import React, { useEffect, useState } from "react";
import "./App.css";
import Rutas from "./route/index";
import { UserProvider, useUsuario } from "./context/user";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from "./components/nav/sidebar/SideBar";
import Content from "./components/nav/content/Content";
import "./App.css";
import Footer from "./components/nav/footer/Footer";
import { DominioProvider, useDominio } from "./context/dominio";

export default () => (
  <DominioProvider>
    <UserProvider>
      <App></App>
    </UserProvider>
  </DominioProvider>
);

function App() {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const {isMohave,dominio}=useDominio()
  
  useEffect(()=>{
    isMohave()
  },[])

  return (
    <Router>
      <div className="App wrapper">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
      </div>
    </Router>
  );
}
