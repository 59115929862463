import React, { Fragment } from "react";
import Header from "../components/header";
import FalladosBody from "../components/fallados";

function Fallados () {
    return (
        <Fragment>
            <FalladosBody/>
        </Fragment>
    )
}

export default Fallados