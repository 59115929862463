import React from "react";
import { useState, useEffect } from "react";
import sendRequest from "../../../conexion";
import Table from 'react-bootstrap/Table'
import './cuenta.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import borrar from '../icon/borrar.png'
import carrito from '../../about/icons/carritoCompras.png'

export default function WishList () {
    const [wishListProducts, setWishListProducts] = useState(undefined);
    const [wishProductos,setWishProduct]=useState([])

    let productos= []
    
    const token = localStorage.getItem('key')

    const buscar = async ()=>{
        try{
            const response = await sendRequest('GET','api/v1/wishlist',{},{'Authorization': token})
            setWishListProducts(response.data.wishlist);
        }
        catch (error){
            console.log(error)
        }
    }

    const cargar = async()=>{
                if (wishListProducts!==undefined) {
                    for (const producto of wishListProducts) {
                        const detailProduct = await sendRequest('GET', `api/v1/productDetail/${producto.product_id}`, {}, {});
                        productos.push(detailProduct.data);
                      }
                }
                setWishProduct(productos)
            }

    useEffect(()=>{
        buscar()
    },[])

    useEffect(()=>{
        cargar()
    },[wishListProducts])

    const click = async (id)=>{
        await sendRequest('DELETE', `api/v1/wishlist/product/${id}`,{},{'Authorization': token})
        buscar()
    }

    const urlImagen = (i) =>{
        const primeraFoto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`
        return primeraFoto
    }

    const agrearCarrito=(id)=>{
        document.location.href = `../product/${id}` 
    }

    return(
        <div className="Contenedor-Pedido">
            {(wishProductos.length!==undefined) 
            ?
                <Table striped>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Nombre</th>
                            <th>Section</th>
                            <th>Precio</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                            {wishProductos?.map(e => {
                                return (
                                    <tr>
                                        <td>
                                            <img className="imagen-wishlist"
                                                src={urlImagen(e.photos.map((img)=>(img.imagePath)))} 
                                                alt="">
                                            </img>
                                        </td>
                                        <td>{e.product.name}</td>
                                        <td>{e.subsections.map((section)=>section.name)}</td>
                                        <td>{e.product.priceMin}</td>
                                        <td>
                                        <button className="button-wish" onClick={()=>click(e.product.id)}>
                                            <img className="like" src={borrar} alt=""/>                                            
                                        </button>  
                                        </td>
                                        <td>
                                        <button className="button-wish" onClick={()=>agrearCarrito(e.product.id,e,e.product.priceMin)}>
                                            <img className="like" src={carrito} alt=""/>                                            
                                        </button>  
                                        </td>
                                    </tr>
                                )
                            })}                        
                    </tbody>
                </Table>
            :
                <>No tiene elementos en la lista</>
            }
        </div>
    )
}
