import React from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useDominio } from '../../../context/dominio';
import './footer.css';


const Footer = () => {
  const {dominio}=useDominio()

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__section">
          {(dominio)
            ? <frameElement>
                <h4 className="footer__section-heading">MOHAVE JEANS</h4>
                <p className="footer__contact-info">Teléfono 1: +5493517686032</p>
                <p className="footer__contact-info">Mail: MOHAVEJEANS@GMAIL.COM</p>
                <p className="footer__contact-info">Dirección: Obispo Oro 353 - Nueva Córdoba</p>
              </frameElement>
            : <frameElement>
                <h4 className="footer__section-heading">GELL JEANS</h4>
                <p className="footer__contact-info">Teléfono 1: +5493517686032</p>
                <p className="footer__contact-info">Mail: GELLJEANSORIGINAL@GMAIL.COM</p>
                <p className="footer__contact-info">Dirección: Obispo Oro 353</p>
              </frameElement>
          }
        </div>
        <div className="footer__section">
          <h4 className="footer__section-heading">Redes</h4>
          <ul className="footer__social-links">
            <li>
              <a href="https://api.whatsapp.com/send?phone=5493517686032" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp className="footer__social-icon" />
              </a>
            </li>
            <li>
              {(dominio)
                ? <a href="https://www.facebook.com/mohavejeans" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="footer__social-icon" />
                  </a>
                : <a href="https://www.facebook.com/gelljeansoriginalok" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="footer__social-icon" />
                  </a>
              }
            </li>
            <li>
              {(dominio)
                ? <a href="https://www.instagram.com/mohavejeans" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="footer__social-icon" />
                  </a>
                : <a href="https://www.instagram.com/gelljeansoriginal" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="footer__social-icon" />
                  </a>
              }
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;