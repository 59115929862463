import React, {Fragment} from "react";
import AboutBody from "../components/about";
import Header from "../components/header/index";

export default function About(){
    return(
        <Fragment>
        <div className="body">
            <div className="Header-About">
                INFORMACION
            </div>
            <div className="Body-About">
                <AboutBody/>
            </div>
            <div className="Footer-About">
            </div>
        </div>
        </Fragment>
    )
}