import React from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import './cart.css'
import { useUsuario } from "../../context/user";
import BodyCuerpo from './cartBody';


export default function MydModalWithGrid(props) {
    const {cart} = useUsuario()
    const tamaño = cart?.data?.cartDetails?.length
    const cuerpo = cart?.data?.cartDetails
    const total = cart?.data?.cart?.totalMinPrice

    const Redireccionar = () => {
        (tamaño === undefined) ? <></> :
            (tamaño !== 0)
                ? cuerpo?.map((info)=>(
                    window.location.href='../Compra/Articulos'
                ))
                :   <></> 
    }
    
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" id="Modal">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Mi Carrito
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid"> 
            <div className='Subtitulos'>
                <div className='Subtitulos-izquierdo'>{tamaño} Articulos</div>
                <div className='Subtitulos-derecho'>Subtotal: {Intl.NumberFormat("de-DE",
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(total)
                    }</div>
            </div>
            <Container className="tbody">
                 
            { (tamaño === undefined) ? <></> :
            (tamaño !== 0)
                ? cuerpo?.map((info)=>(
                    <BodyCuerpo cuerpo={info} index={info.cartDetail.id} />
                ))
                :<div className='cart-body'>
                    No agrego productos al carrito
                </div> 
                }
            <Row>
                <div className='Contenedor-Cart'>
                    <div className='izquierda-cart'>
                        <strong>Total</strong>
                    </div>
                    <div className='derecha-cart'>
                        <strong>{Intl.NumberFormat("de-DE",
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(total)
                                }
                        </strong>
                    </div>
                </div>
            </Row>      
            <Row>
                <div className='contenedor-button'>
                    <button className='button-cart' onClick={Redireccionar}>Pagar</button>
                </div> 
            </Row>
            </Container>    
        </Modal.Body>
        </Modal>
    );
}

