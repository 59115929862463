import React from "react";
import MenuPrincipal from "../navbars/primary";

const Header = ({ toggleSidebar }) => {
  return (
    <div>
      <MenuPrincipal toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Header;
