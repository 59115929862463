import React, { Fragment } from "react";
import Header from "../components/header";
import NavUser from "../components/User";
import Wishlist from "../components/User/cuenta/wishlist";
import Login from '../components/login'

import {useUsuario} from '../context/user'

function Wish () {
    const {user} = useUsuario()

    return (
        <Fragment>
            {(user!==undefined)
                ?   <div>
                        <NavUser/>
                        <Wishlist/>
                    </div>
                :   <div>
                        <Login/>

                    </div>
                }

        </Fragment>
    )
}

export default Wish