import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDominio } from "../../../context/dominio";

const SideBar = ({ isOpen, toggle }) => {
  const { dominio } = useDominio();

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        {dominio ? <h3>Mohave Jeans</h3> : <h3>Gell Jeans</h3>}
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3" style={{ color: "#fff" }}>
          <NavItem>
            <NavLink tag={Link} to={"/new"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              New
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Mohave"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Mohave
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Gell"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Gell
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Tiro-Alto"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Tiro alto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Tiro-Medio"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Tiro medio
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Fallados"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Fallados
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/Sale"} style={{ color: "black" }}>
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ marginRight: "6px" }}
              />
              Sale
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
