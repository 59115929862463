import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useMapEvent } from "react-leaflet/hooks";
import "leaflet/dist/leaflet.css";
import Markers from "./marker";
import "./maps.css";
import sendRequest from "../../conexion";
import { useState } from "react";

function Ubication(location) {
  const [list, setList] = useState([]);
  const [position, setPosition] = useState([]);

  const load = async () => {
    const response = await sendRequest(
      "GET",
      "/api/v1/dashboard/locations",
      null
    );

    setList(response.data.data);
    setPosition([
      response.data.data[0].latitude,
      response.data.data[0].longitude,
    ]);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="body-map">
      <div className="Maps-izquierdo">
        {position.length ? (
          <MapContainer center={position} zoom={14} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {list.map((location) => (
              <Markers location={location} />
            ))}
          </MapContainer>
        ) : null}
      </div>
      <div className="Maps-derecho">
        {list.map((location) => (
          <button
            className="button-maps"
            onClick={() =>
              MapContainer.setCenter(location.latitude, location.longitude)
            }
          >
            <div>{location.address}</div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Ubication;
