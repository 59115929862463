import React from "react";
import { useEffect, useState } from "react";
import sendRequest from "../../conexion";
import {CardGroup} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../cards/cards.css'
import Cards from "../cards/cards";
import Loader from "../utils/loader";

const MohaveBody = () => {
    const [imagenes, SetImagen]= useState([])

    const cargar = async () => {
        try{
            const response = await sendRequest('GET', 'api/v1/productMarca/2', null)
            let products = response.data.data.filter(product => !product.product.outlet);
            SetImagen(products)
        } catch (error) {
            console.log(error)
        }
    }

    const urlImagen = (i) =>{
        const primeraFoto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`
        return primeraFoto
    }

    const session = (i) =>{
        const primeraFoto = `${i[0]}`
        return primeraFoto
    }

    useEffect(()=>{
        cargar()
    },[])

    return(
        <div className="Cont-Cards-TA">
            {(imagenes.length===0)
            ? <Loader/>
            :
            <CardGroup>
                {
                imagenes.map((img) => (
                        <Cards
                            id={img.product.id}
                            name={img.product.name}
                            url={urlImagen(img.productPhotos.map((i)=>(i.imagePath)))}
                            session={session(img.subsections.map((i)=>(i.name)))}
                            precio={
                                Intl.NumberFormat("de-DE",
                                {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(img.product.priceMin)
                            }
                        />
                ))
                }
            </CardGroup>}
        </div>
    )
}

export default MohaveBody

