import React, { Fragment, useEffect } from "react";
import Header from "../components/header";
import NavUser from "../components/User";
import Cuenta from "../components/User/cuenta";
import Login from '../components/login'

import {useUsuario} from '../context/user'

function User () {
    const {user,cargarUser} = useUsuario()
    const token = localStorage.getItem('key')

    useEffect(()=>{
        if (token!==undefined) {
            cargarUser(token)
        }
    },[])

    return (
        <Fragment>
            {(user!==undefined)
                ?   <div>
                        <NavUser/>
                        <Cuenta />
                    </div>
                :   <div>
                        <Login/>

                    </div>
                }

        </Fragment>
    )
}

export default User