import React, { Fragment } from "react";
import Header from "../components/header";
import SaleBody from "../components/sale";

function Sale () {
    return (
        <Fragment>
            <SaleBody/>
        </Fragment>
    )
}

export default Sale