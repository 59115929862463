import React, { useState, useEffect } from "react";
import sendRequest from "../../conexion";
import "./product.css";
import Photos from "./photos";
import ProductoDetail from "./productDetails";
import { useDominio } from "../../context/dominio";

export default function Product(props) {
  const id = props.id;
  const [imagenes, SetImagen] = useState([]);
  const [principal, setPrincipal] = useState("");
  const [product, setProdcut] = useState([]);

  const urlImagen = (i) => {
    if (typeof(i)==='object'){
        return `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`
    } else {
        return `https://gelljeans.s3-sa-east-1.amazonaws.com${i}`
    }
  };

  const cargar = async (i) => {
    try {
      const imagen = await sendRequest(
        "GET",
        `api/v1/productDetail/${id}`,
        null
      );
      const image = imagen.data;
      setProdcut(image);
      SetImagen(image.photos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    cargar();
  }, []);

  const Change = async (i) => {
    setPrincipal(urlImagen(i));
  };

  return (
    <div className="ContenedorProduct">
      <div className="ImagesProduct">
        <div className="ListImages">
          {imagenes !== undefined ? (
            imagenes.map((photo) => (
              <button
                key={photo.id}
                className="buttonPhotos"
                onClick={() => {
                  Change(photo.imagePath);
                }}
              >
                <Photos photo={photo} />
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="PrincipalImage">
          {imagenes !== undefined ? (
            <div className="PrincipalPhoto">
              <img
                className="ImgPhoto"
                src={
                  principal === ""
                    ? urlImagen(imagenes.map((img) => img.imagePath))
                    : principal
                }
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="DetailProduct">
        {product.status === "success" ? (
          <ProductoDetail product={product} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
