import React from "react";
import {Link} from "react-router-dom";
import './menu.css'

function Menu() {
    return(
        <div className="iconos">
            <Link to="/Maps"><button className="icon" id="GPS"></button></Link>
            <Link to="/About"><button className="icon" id="Help"></button></Link>
            <Link to="/Account"><button className="icon" id="Person"></button></Link>
        </div>
    )
}

export default Menu