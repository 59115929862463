import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

function MenuSecundary() {
  return (
    <div>
      <Link className="link" to="/New">
        NEW
      </Link>
      <Link className="link" to="/Mohave">
        MOHAVE
      </Link>
      <Link className="link" to="/Gell">
        GELL
      </Link>
      <Link className="link" to="/Tiro-Alto">
        TIRO ALTO
      </Link>
      <Link className="link" to="/Tiro-Medio">
        TIRO MEDIO
      </Link>
      <Link className="link" to="/Fallados">
        FALLADOS
      </Link>
      <Link className="link" to="/Sale">
        SALE
      </Link>
    </div>
  );
}

export default MenuSecundary;
