import React, { Fragment } from "react";
import Header from "../components/header";
import Product from "../components/product";
import {useParams} from "react-router-dom"

function Products () {
    const {id} = useParams()
    return (
        <Fragment>
            <Product id={id}/>
        </Fragment>
    )
}

export default Products