import React, {useEffect, useState} from "react";
import { useUsuario } from "../../context/user";
import {InputGroup,Button,Form} from 'react-bootstrap';
import {ToggleButtonGroup,ToggleButton,ProgressBar} from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import sendRequest from "../../conexion";
import Articulos from "./articulosPago"

export default function Pago (){
    const {cart,dirPago} = useUsuario()
    const [checked, setChecked] = useState(false);
    const subtotal = cart?.data?.cart?.totalMinPrice
    const {Envio,Descuento,Porcentaje} = useParams()
    const [descuento,setDescuento]=useState(-1*Descuento)
    const [idDescuento,setIdDescuento]=useState(Porcentaje)
    const envio=((Envio==='12') ? 0 :270)
    const [total,setTotal]=useState(0)
    const token= localStorage.getItem('key')
    const orderItems=[]

    const limpiar = ()=>{
        for (var i=0;i<orderItems.length+3;i++){
            orderItems.shift()
        }
    }

    const CargarItems = ()=>{
        limpiar()
        cart?.data?.cartDetails.map((i)=>{
            var cuerpo = {
                "idOrder":0,
                "idProduct":i.cartDetail.idProduct,
                "idProductDetail":i.cartDetail.idProductDetail,
                "quantity":i.cartDetail.quantity,
                "unitPrice":i.product.priceMin
            }
            orderItems.push(cuerpo)
        })
    }

    const AplicarDescuento = async ()=>{
        try{
            const palabra = document.getElementById("descuento").value
            const id=cart.data.cart.id
            const desc = await sendRequest('GET',
                `api/v1/order/searchDiscount/${token}/${id}/${palabra}`,
                null,
                {'Authorization': token})
            setTimeout((desc.data.data!==null)
                ?setDescuento(-1*(desc?.data?.data?.value * subtotal)/100)
                :setDescuento(-1*Descuento))

            setTimeout((desc.data.data!==null)
                ?setIdDescuento(desc.data?.data?.id)
                :setIdDescuento(Porcentaje))
        }catch (error){
            console.log(error)
        }
    }

    useEffect(()=>{
        setTimeout(setTotal(parseFloat(subtotal)+envio+parseFloat(descuento)),2000)
    },[subtotal, envio, descuento])

    const Pagar = async ()=>{
        CargarItems()
        let shipment,Id
        try{
            const idEnvio = await sendRequest ('GET','/api/v1/order/getShipments',null,{'Authorization': token})
            idEnvio.data.shipment.map((i)=>{if(i.id==Envio){
                Id =Envio
                shipment = i.name
            }})
            const cuerpo = {
                "payment_address_id": dirPago.data[0].id,
                "order": {
                    "totalPrice": total,
                    "enterpriseName": "",
                    "country": dirPago.data[0].country,
                    "province": dirPago.data[0].province,
                    "city": dirPago.data[0].city,
                    "postalCode": dirPago.data[0].postal_code,
                    "streetName": dirPago.data[0].street_name,
                    "number": dirPago.data[0].number,
                    "floor": dirPago.data[0].floor,
                    "dpto": dirPago.data[0].department,
                    "iva": 1,
                    "idPayment": 3,
                    "observations": "-",
                    "status": "-",
                    "shipment": shipment,
                    "outlet": false,
                    "mayor": false,
                    "discountId": (idDescuento!=="0")?idDescuento:null,
                    "shipmentId": Id
                },
                "orderItems": orderItems
            }
            const orden = await sendRequest('POST', 'api/v1/order/createOrder',cuerpo,{'Authorization': token})
            const pago= await sendRequest ('GET',`api/v1/order/pagarMP/${orden.data.order.id}`,null,{'Authorization': token})
            window.location.href=pago.data.mp
            await sendRequest('DELETE',`api/v1/cart/deleteFullCart`,null,{'Authorization': token})
        }
        catch(error){console.log(error)}
    }

    return(
        <div>
            <div className="top">
                <div className="DEPLeft">
                    <div className="Left-Titulo"> Envio &emsp;&emsp;&emsp;&emsp; Pago</div>
                    <div className="Left-Titulo"><ProgressBar now={100} /></div>
                    <div className="Left-Subtitulo"><div className="subtitulo-Body">Metodo de Pago</div></div>
                    <div className="Left-Pago">
                        <div>Cupones de descuento o GIFT CARD</div>
                        <InputGroup className="mb-3">
                            <Form.Control
                            placeholder="Introducir codigo de descuento"
                            aria-describedby="basic-addon2"
                            id="descuento"
                            />
                            <Button variant="secondary" id="button-addon2" onClick={()=>AplicarDescuento()}>
                            APLICAR DESCUENTO
                            </Button>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Control
                            placeholder="Introducir codigo de GIFT CARD"
                            aria-describedby="basic-addon2"
                            />
                            <Button variant="secondary" id="button-addon2">
                            APLICAR GIFT CARD
                            </Button>
                        </InputGroup>
                        <div>
                            <div className="Titulo-Metodo-Pago">Metodo de Pago</div>
                            <div className="Metodo-Pago">
                                <input type="radio" name="pago" defaultChecked/>
                                <div className="nombre-Metodo-Pago">Mercado Pago</div>
                            </div>
                        </div>
                        <div className="Regalo-Metodo-Pago">¿ES UN REGALO?</div>
                        <div>
                            <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                                <ToggleButton id="tbg-radio-1" value={1} className="btn-secondary">
                                    NO QUIERO BOLSA
                                </ToggleButton>
                                <ToggleButton id="tbg-radio-2" value={2} className="btn-secondary">
                                    QUIERO BOLSA
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className="tableTotal">
                            <div className="lefttable">TOTAL A PAGAR</div>
                            <div className="rigthtable">$ {Intl.NumberFormat("de-DE",
                                {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(total)
                            }</div>
                        </div>
                        <button className="Buton-Final-Compra" onClick={()=>Pagar()}>PAGAR</button>
                    <div/>
                </div>
                </div>
                <div className="MPRight">
                    <div className="MPRightAlto">
                        <div className="MPSubrayado"><h2>Resumen de tu pedido</h2></div>
                        <div className="MPSubrayado">
                            <div className="MPSubtotales">
                                <div className="lefttable">Subtotal</div>
                                <div className="righttable">$ {Intl.NumberFormat("de-DE",
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(subtotal)
                                    }
                                </div>
                            </div>
                            <div className="MPSubtotales">
                                <div className="lefttable">Descuento</div>
                                <div className="righttable">$ {Intl.NumberFormat("de-DE",
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(descuento)
                                    }
                                </div>
                            </div>
                            <div className="MPSubtotales">
                                <div className="lefttable">
                                    {(Envio==='1')
                                        ? <>Envio a domicilio</>
                                        : (Envio ==='2')
                                            ? <>Envio a sucursal</>
                                            : <>Retiro en local</>}
                                </div>
                                <div className="righttable">
                                    $ {Intl.NumberFormat("de-DE",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(envio)
                                    }
                                </div>
                            </div>
                            <div className="MPDireccion">{(Envio==='1')
                                        ? <>Entrega de 2 a 7 dias habiles</>
                                        : (Envio ==='2')
                                            ? <>Entrega de 2 a 7 dias habiles</>
                                            : <>Obispo Oro 353 lunes a viernes - 10:00 a 14:00 y 17:00 a 20:00</>}
                                        </div>
                        </div>
                        <div className="MPtotal">
                            <div className="lefttable"><h2>Total del pedido</h2></div>
                            <div className="righttable"><h2>$
                                {Intl.NumberFormat("de-DE",
                                {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(total)
                                }</h2></div>
                        </div>
                        <div className="DetalleFactura">
                            {cart?.data?.cartDetails?.map((info)=>(
                                <Articulos info={info} index={info.cartDetail.id} />
                            ))}
                        </div>
                    </div>
                    <div className="MPRightBajo">
                        <div>METODO DE ENVIO</div>
                        {(Envio==='1')
                            ? <>Entrega de 2 a 7 dias habiles</>
                            : (Envio ==='2')
                                ? <>Entrega de 2 a 7 dias habiles</>
                                : <>Obispo Oro 353 lunes a viernes - 10:00 a 14:00 y 17:00 a 20:00</>}
                    </div>
                </div>
            <div >
            </div>
            </div>
        </div>
    )
}