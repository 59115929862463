import React from "react";
import { useState, useMemo } from "react";
import sendRequest from "../conexion/index"
import { useDominio } from "./dominio";


const UserContext = React.createContext();

export function UserProvider(props) {
    const [user, setUser] = useState();
    const [dirEnvio,setEnvio] = useState();
    const [dirPago,setPago] = useState();
    const [cart,setCart] = useState()
    const {isOutletEnable,isLineEnable}=useDominio()

    const selectCart = async(key) => {
        sendRequest('GET', 'api/v1/cart/getCart/0',null,{'Authorization': key})
            .then(
                (response)=>
                    {if(response.data.cartDetails.length!==0){
                        isLineEnable(true)
                        localStorage.setItem('outlet',0)
                        getCart(key,0)
                    } else {
                        sendRequest('GET', 'api/v1/cart/getCart/1',null,{'Authorization': key})
                            .then((response)=>{
                                if(response.data.cartDetails.length!==0){
                                    isOutletEnable(true)
                                    localStorage.setItem('outlet',1)
                                    getCart(key,1)
                                } else {
                                    isLineEnable(false)
                                    isOutletEnable(false)
                                    localStorage.setItem('outlet','')
                                    
                                }
                            })
                    }
                    
                    }
            )
    }

    const addCart = async(idProduct,idProductDetail,unitPrice,outlet)=>{    
        try{
            const body = {
                idProduct: `${idProduct}`,
                idProductDetail: `${idProductDetail}`,
                quantity: 1,
                unitPrice: unitPrice
            }
            await sendRequest('POST', `api/v1/cart/addItem/${outlet}`,body,{'Authorization': localStorage.getItem('key')})
            if (outlet===1) {
                isOutletEnable(true)
                isLineEnable(false)
                localStorage.setItem('outlet',outlet)
                getCart(localStorage.getItem('key'),outlet)
            } else {
                isLineEnable(true)
                isOutletEnable(false)
                localStorage.setItem('outlet',outlet)
                getCart(localStorage.getItem('key'),outlet)
            }
            selectCart()
            return (true)
        } catch (error) {
            console.log(error)
        }
    }

    const getCart = async (key) => {
        try{
            let list
            list = await sendRequest('GET', `api/v1/cart/getCart/${localStorage.getItem('outlet')}`,null,{'Authorization': key})
            if (list.data.cart===null){
                console.log(`vacia`)
            } else {                
                setCart(list)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const direccionEnvio= async (key)=>{
        try{
            const envio = await sendRequest('GET', 'api/v1/address',{},{'Authorization': key})
            setEnvio(envio.data)
        } catch (error) {
            console.log(error)
        }
    }

    const direccionPago= async (key)=>{
        try{
            const envio = await sendRequest('GET','/api/v1/payment-address',{},{'Authorization': key})
            setPago(envio.data)
        } catch (error) {
            console.log(error)
        }
    }
   
    const cargarDatos = async (key)=>{
        let i = false
        do {
            if(key!==undefined){
                selectCart(key).then(()=>{})
                direccionPago(key)
                direccionEnvio(key)
                i=true
                
            }else {console.log(undefined)}
        } while (i === false)
    }

    const cargarUser= async (key)=>{
        try{
            const {data} = await sendRequest('GET','/api/v1/user/info',{},{'Authorization': key})
            if(data.status ==="success"){
                setUser(data.data)
            }
            cargarDatos(key)
        } catch (error) {
            console.log(error)
        }
    }

    async function login(email, password) {
        try{
            const {data} = await sendRequest('POST', '/api/v1/login', {
                email,
                password
            })
            if (data.status === "success"){
                cargarUser(data.api_key)
                localStorage.setItem('key',data.api_key)
                return(true)
            }
        } catch (error) {
            return (false)
        }
    }

    async function register(name, surname, email, password, phone) {
        try{
            const {data} = await sendRequest('POST', '/api/v1/register', {
                name,
                surname,
                email,
                password,
                phone
            })
            login(email,password)
        } catch (error) {
            console.log(error)
        }
    }

    function logout(){
        setUser([])
        setEnvio()
        setPago()
        setCart()
    }

    const value = useMemo(()=>{
        return({
            user,
            dirEnvio,
            dirPago,
            cart,
            login,
            register,
            logout,
            cargarUser,
            direccionEnvio,
            direccionPago,
            addCart,
            getCart,
            selectCart
        })
    }, [user,dirPago,dirEnvio,cart])

    return < UserContext.Provider value={value} {...props}/>

}

export function useUsuario() {
    const context= React.useContext(UserContext);
    if(!context) {
        throw new Error ('useUsuario debe estar dentro del proveedor')
    }
    return context
}