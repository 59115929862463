import React, { Fragment } from "react";
import Header from "../components/header";
import GellBody from "../components/gell";

function Gell () {
    return (
        <Fragment>
            <GellBody/>
        </Fragment>
    )
}

export default Gell