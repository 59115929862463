import React, {Fragment} from "react";
import Header from "../components/header/index";
import ArticulosDescuentos from "../components/compras/articulosDescuentos";

export default function CompraEnvio(){
    return(
        <Fragment>
        <div className="body">
            <div className="Body-Compra">
                <ArticulosDescuentos />
            </div>
        </div>
        </Fragment>
    )
}