import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navs.css";
import Menu from "./menu";
import MenuSecundary from "../secundary";
import LeftMenu from "../leftMenu/index";
import MydModalWithGrid from "../../cart/index";
import { useUsuario } from "../../../context/user";
import Logo from "../../logo/index";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";

const MenuPrincipal = ({ toggleSidebar }) => {
  const [menuState, setMenu] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { user, cargarUser, cart } = useUsuario();
  const token = localStorage.getItem("key");
  const tamaño = cart?.data?.cartDetails?.length;

  useEffect(() => {
    if (token !== undefined) {
      cargarUser(token);
    }
  }, []);

  const clickMenuFlotante = () => {
    setMenu(!menuState);
  };

  const clickModal = () => {
    if (user !== undefined) {
      setModalShow(true);
    } else {
      document.location.href = `../Account`;
    }
  };

  return (
    <div>
      <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} />
      <div className="contenedor-menu">
        <div className="left">
        <Button className="button-left" color="grey" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        </div>
        <Logo />
        <div className="right">
          <Link to="../search">
            <button className="icon" id="Search"></button>
          </Link>
          <Menu />
          <button className="icon" onClick={clickModal} id="Market"></button>
          {tamaño !== undefined ? tamaño : 0}
        </div>
      </div>
      <div className="secundary">
        <MenuSecundary />
        {menuState === true ? <LeftMenu /> : <></>}
      </div>
    </div>
  );
};

export default MenuPrincipal;
