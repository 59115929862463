import React, { Fragment } from "react";
import Header from "../components/header";
import Carrousel, { MainProducts } from "../components/home";
import HomeInfo from "../components/nav/homeInfo/HomeInfo";

function Home() {
  return (
    <Fragment>
      <Carrousel />
      <HomeInfo />
    </Fragment>
  );
}

export default Home;
