import React,{useState} from "react";
import './cart.css'
import borrarimg from '../User/icon/borrar.png'
import sendRequest from "../../conexion";
import { useUsuario } from "../../context/user";
import { useDominio } from "../../context/dominio";

export default function BodyCuerpo (props){
    const {getCart,selectCart} = useUsuario()
    const {isOutletEnable,isLineEnable} = useDominio()
    const cuerpo = props.cuerpo
    let [cantidad,setCantidad] = useState(cuerpo.cartDetail.quantity)
    const idProduct = cuerpo.cartDetail.idProductDetail
    const lista = props.index
    const token = localStorage.getItem('key')
    
    const urlImagen = (i) =>{
        return `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}` 
    }

    const quitar = async ()=>{
        if (cantidad > 1) {
          console.log()
          setCantidad(--cantidad)
          const body={
              idProductDetail: `${idProduct}`,
              quantity: `${cantidad}`
            }
          try{
            if (cuerpo.product.outlet){
              await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/1`,body,{'Authorization': token})
              getCart(token,1)
            } else {
              await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/0`,body,{'Authorization': token})
              getCart(token,0)
            }
          } catch (error) {
            console.log(error)
          }
        }
    }

    const sumar = async ()=>{
      setCantidad(++cantidad)
      const body={
          idProductDetail: `${idProduct}`,
          quantity: `${cantidad}`
        }
      try{
        if (cuerpo.product.outlet){
          await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/1`,body,{'Authorization': token})
          getCart(token,1)
        }else {
          await sendRequest('POST', `api/v1/cart/editCartItems/${lista}/0`,body,{'Authorization': token})
          getCart(token,0)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const borrar = async ()=>{
      try{
        if (cuerpo.product.outlet){
          await sendRequest('POST', `api/v1/cart/deleteItem/${lista}/1`,null,{'Authorization': token})
          getCart(token,1)
          selectCart(token)
        } else {
          await sendRequest('POST', `api/v1/cart/deleteItem/${lista}/0`,null,{'Authorization': token})
          getCart(token,0)
          selectCart(token)
        }
        
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <tbody>
      <tr>
        <td>
          <img
            className="ImgCart"
            src={urlImagen(cuerpo.productPhotos.map((img) => img.imagePath))}
            alt=""
          />
        </td>
        <td className="TableName">
          <div>{cuerpo.product.name}</div>
          <div>Talle: {cuerpo.productDetail.map((i) => i.size)}</div>
          <div>${Intl.NumberFormat("de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(cuerpo.product.priceMin)
                }</div>
        </td>
        <td className="TableCantidad">
          <button className="QualityBoton" onClick={quitar}>
            -
          </button>
          {cantidad}
          <button className="QualityBoton" onClick={sumar}>
            +
          </button>
        </td>
        <td className="TableDelete">
          <button className="borrarCart" onClick={borrar}>
            <img className="imgCartBorrar" src={borrarimg} alt="" />
          </button>
        </td>
      </tr>
    </tbody>

    )
}