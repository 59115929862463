import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { useDominio } from "../../context/dominio";

function Logo() {
  const { dominio, isMohave } = useDominio();

  useEffect(() => {
    isMohave();
  });

  return (
    <div className="logo-contenedor">
      <Link className="link" to="/">
        {dominio ? (
          <button className="button-logo"></button>
        ) : (
          <button className="button-logo-gell"></button>
        )}
      </Link>
    </div>
  );
}

export default Logo;
