import React, { useState, useEffect } from "react";
import { useUsuario } from "../../context/user";
import "./login.css";

export default function Login() {
  const { login } = useUsuario();
  const [error, setError] = useState("");
  const [estado, setEstado] = useState("pendiente");

  const Ingresar = () => {
    const email = document.getElementById("Email").value;
    const password = document.getElementById("Password").value;
    login(email, password).then((res) => {
      res === true ? setEstado("Ingresado") : setEstado("Rechazado");
    });
  };

  document.addEventListener("keyup", function (event) {
    if (event.keyCode === 13) {
      Ingresar();
    }
  });

  useEffect(() => {
    if (estado !== "Ingresado") {
      if (estado === "Rechazado") {
        setError("Usuario o contraseña incorrectos");
        console.log(estado);
      }
    }
  });

  return (
    <div className="formulario">
      <div className="Login">
      <h3>¡Ingresá!</h3>
        <div className="contenedor-label">
          <input className="input-login" id="Email" type="email" placeholder="Email" />
        </div>
        <div className="contenedor-label">
          <input className="input-login" id="Password" type="password" placeholder="Contraseña" />
        </div>
        <button className="Button-ingresar" onClick={Ingresar}>
          Ingresar
        </button>
        <div className="Error">{error}</div>
        <div className="contenedor-registrar">
          <p className="label">
            Si no tienes cuenta, regístrate haciendo click
          </p>
          <button
            className="Button-registrar"
            onClick={() => {
              document.location.href = "../Account/Register";
            }}
          >
            aquí
          </button>
        </div>
      </div>
    </div>
  );
}
