import React, { useState } from "react";
import { useEffect } from "react";
import sendRequest from "../../conexion";
import Carousel from "react-bootstrap/Carousel";
import "./home.css";
import { CardGroup } from "react-bootstrap";
import Cards from "../cards/cards";
import Loader from "../utils/loader";
import { useDominio } from "../../context/dominio";

export default function Carrousel() {
  const [imagen, setImagen] = useState([]);
  const [text, setText] = useState("");

  const cargar = async () => {
    try {
      const images = await sendRequest(
        "GET",
        "api/v1/dashboard/carrousel",
        null
      );
      setImagen(images.data.images);
      setText(images.data.description);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    cargar();
  }, []);

  return (
    <div className="Carrousel">
      <Carousel variant="dark">
        {imagen.map((img) => (
          <Carousel.Item key={img.id}>
            <div className="image-container d-flex flex-row justify-content-center">
              <img
                className="d-block"
                src={img}
                alt="First slide"
                height="480px"
                widht="400px"
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="caption-content">
        <p>{text}</p>
      </div>
    </div>
  );
}

export function MainProducts() {
  const [imagenes, SetImagen] = useState([]);
  const { dominio } = useDominio();

  const cargar = async () => {
    try {
      console.log(`vacio`);

      if (dominio) {
        const response = await sendRequest(
          "GET",
          "/api/v1/allActiveProducts?idMarca=2",
          null
        );
        let products = response.data.data
          .filter((product) => !product.product.outlet)
          .slice(0, 3);
        SetImagen(products);
      } else {
        const response = await sendRequest(
          "GET",
          "/api/v1/allActiveProducts?idMarca=3",
          null
        );
        let products = response.data.data
          .filter((product) => !product.product.outlet)
          .slice(0, 3);
        SetImagen(products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const urlImagen = (i) => {
    const primeraFoto = `https://gelljeans.s3-sa-east-1.amazonaws.com${i[0]}`;
    return primeraFoto;
  };

  const session = (i) => {
    const primeraFoto = `${i[0]}`;
    return primeraFoto;
  };

  useEffect(() => {
    cargar();
  }, [dominio]);

  return (
    <div className="Cont-Cards-TA" style={{ marginTop: "100px" }}>
      {imagenes.length === 0 ? (
        <Loader />
      ) : (
        <CardGroup>
          {imagenes.map((img) => (
            <Cards
              id={img.product.id}
              name={img.product.name}
              url={urlImagen(img.productPhotos.map((i) => i.imagePath))}
              session={session(img.subsections.map((i) => i.name))}
              precio={img.product.priceMin}
            />
          ))}
        </CardGroup>
      )}
    </div>
  );
}
