import React from "react";
import { useState, useMemo } from "react";

const DominioContext = React.createContext();

export function DominioProvider(props) {
  const [dominio, setDominio] = useState();
  const [outlet,setOutlet] = useState(false)
  const [line,setLine] = useState(false)

  const isOutletEnable = (valor)=>{
    setOutlet(valor)
  }

  const isLineEnable = (valor)=>{
    setLine(valor)
  }

  const isMohave = () => {
    setDominio(window.location.hostname === 'mohavejeans.com');
  };

  const value = useMemo(() => {
    return {
      dominio,
      outlet,
      line,
      isMohave,
      isOutletEnable,
      isLineEnable,
    };
  }, [dominio,outlet,line]);

  return <DominioContext.Provider value={value} {...props} />;
}

export function useDominio() {
  const context = React.useContext(DominioContext);
  if (!context) {
    throw new Error("useDominio debe estar dentro del proveedor");
  }
  return context;
}
