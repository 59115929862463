import React, {useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import sendRequest from "../../../conexion";
import './cuenta.css'

export default function BodyOrder(){
    const [orders,setOrders]= useState([])
    const token= localStorage.getItem('key')
    
    const cargar= async ()=>{
        const data = await sendRequest('GET','/api/v1/order',null,{'Authorization': token})
        setOrders(data.data.order)
    }

    useEffect(()=>{
        cargar()
    },[])

    return(
        <div className="order-body">
            <Table striped>
                <thead>
                    <tr>
                    <th>PEDIDO</th>
                    <th>FECHA</th>
                    <th>ENVIAR A</th>
                    <th>TOTAL DEL PEDIDO</th>
                    <th>ESTADO</th>
                    <th>TRAKING</th>
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((i)=>{
                        return(
                            <tr>
                                <td>{i.id}</td>
                                <td>{i.created_at.substring(0,10)}</td>
                                <td>{i.shipment.substring(0,30)}</td>
                                <td>{i.totalPrice}</td>
                                <td>{i.status}</td>
                                <td>{i.number}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}