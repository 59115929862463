import React from "react";
import CrearDirPago from "../components/User/edicion-cuenta/crear-dirpago";
import "../components/User/cuenta/cuenta.css";
import Header from "../components/header";

export default function DirPAgoNueva() {
  return (
    <div>
      <CrearDirPago />
    </div>
  );
}
