import React, { useState } from "react";
import { useUsuario } from "../../context/user";
import "./index.css";

export default function Registrer() {
  const { register } = useUsuario();
  const [error, setError] = useState("");
  const registrar = () => {
    const nombre = document.getElementById("Nombre").value;
    const apellido = document.getElementById("Apellido").value;
    const email = document.getElementById("Email").value;
    const password = document.getElementById("Password").value;
    const phone = document.getElementById("Telefono").value;
    register(nombre, apellido, email, password, phone).catch(
      setError(`El correo ingresado ya se encuentra registrado.`)
    );
  };

  return (
    <div className="formulario">
      <div className="Login">
        <h3>¡Registrate!</h3>
        <div className="contenedor-label">
          <input className="input-register" id="Nombre" placeholder="Nombre" />
        </div>
        <div className="contenedor-label">
          <input
            className="input-register"
            id="Apellido"
            placeholder="Apellido"
          />
        </div>
        <div className="contenedor-label">
          <input
            className="input-register"
            id="Email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="contenedor-label">
          <input
            className="input-register"
            id="Telefono"
            placeholder="Telefono"
          />
        </div>
        <div className="contenedor-label">
          <input
            className="input-register"
            id="Password"
            type="password"
            placeholder="Contraseña"
          />
        </div>

        <button className="Button-ingresar" onClick={registrar}>
          Registrarse
        </button>
        {error && (
          <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
            {error}
          </p>
        )}

        <div className="contenedor-registrar">
          <p className="label">¿Ya tienes cuenta?</p>
          <button
            className="Button-registrar"
            onClick={() => {
              document.location.href = "../Account";
            }}
          >
            Ingresa
          </button>
        </div>
      </div>
    </div>
  );
}
