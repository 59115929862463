import React, {useEffect, useState} from "react";
import {Col, Form, Row} from 'react-bootstrap';
import { useUsuario } from "../../../context/user";
import './cuenta.css'
import sendRequest from "../../../conexion";

export default function DireccionEnvio (){
    const [validated, setValidated] = useState(false);
    const {dirEnvio,direccionEnvio} = useUsuario()
    const token= localStorage.getItem('key')
    const [idEnvio,setID]= useState("")
    const [country,setCountry]=useState("")
    const [province,setProvince] = useState("")
    const [city,setCity]=useState("")
    const [postal_code,setCode]= useState("")
    const [street_name,setSteet]= useState("")
    const [number,setNumber]= useState("")
    const [floor,setFloor]=useState("")
    const [department,setDepartment]= useState("")

    useEffect(()=>{
        if (dirEnvio!==undefined){
            setID(dirEnvio.data[0].id)
            setCountry(dirEnvio.data[0].country)
            setProvince(dirEnvio.data[0].province)
            setCity(dirEnvio.data[0].city)
            setCode(dirEnvio.data[0].postal_code)
            setSteet(dirEnvio.data[0].street_name)
            setNumber(dirEnvio.data[0].number)
            setFloor(dirEnvio.data[0].floor)
            setDepartment(dirEnvio.data[0].department)
        }
    },[dirEnvio])

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
    };

    const send= async()=>{
        const country= document.getElementById("country").value
        const province= document.getElementById("province").value
        const city= document.getElementById("city").value
        const postal_code= document.getElementById("postalCode").value
        const street_name= document.getElementById("streetName").value
        const number= document.getElementById("nro").value
        const floor= document.getElementById("floor").value
        const department= document.getElementById("department").value
        try{
            await sendRequest('PUT', `api/v1/address/${idEnvio}`,
            {
                country,
                province,
                city,
                postal_code,
                street_name,
                number,
                floor,
                department
            },{'Authorization': token})
            direccionEnvio(token)
        } catch (error) {
            console.log(error)
        }
    }

    return (
    <div className="Cuenta-body">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
            <Form.Group as={Col} md="3" controlId="country">
            <Form.Label>Pais</Form.Label>
            <Form.Control type="text" placeholder="Pais" defaultValue={country} required />
            <Form.Control.Feedback type="invalid">
                Ingrese un Pais valido.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="province">
            <Form.Label>Cuidad</Form.Label>
            <Form.Control type="text" placeholder="Cuidad" defaultValue={province} required />
            <Form.Control.Feedback type="invalid">
                Ingrese una cuidad valida.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="city">
            <Form.Label>Localidad</Form.Label>
            <Form.Control type="text" placeholder="Localidad" defaultValue={city} required />
            <Form.Control.Feedback type="invalid">
                Ingrese una localidad valida.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="postalCode">
            <Form.Label>CCPP</Form.Label>
            <Form.Control type="number" placeholder="CCPP" defaultValue={postal_code} required />
            <Form.Control.Feedback type="invalid">
                Ingrese un Código Postal valido.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="streetName">
            <Form.Label>Calle</Form.Label>
            <Form.Control type="text" placeholder="Nombre de la calle" defaultValue={street_name} required />
            <Form.Control.Feedback type="invalid">
                Ingrese una calle valida.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="nro">
            <Form.Label>Altura</Form.Label>
            <Form.Control type="number" placeholder="Numero de la calle" defaultValue={number} required />
            <Form.Control.Feedback type="invalid">
                Ingrese una altura de calle valida.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="floor">
            <Form.Label>Piso</Form.Label>
            <Form.Control type="number" placeholder="Numero de Piso" defaultValue={floor} />
            <Form.Control.Feedback type="invalid">
                Ingrese un piso valido.
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="department">
            <Form.Label>Departamento</Form.Label>
            <Form.Control type="text" placeholder="Departamento" defaultValue={department} />
            <Form.Control.Feedback type="invalid">
                Ingrese un departamento valido.
            </Form.Control.Feedback>
            </Form.Group>
        </Row>
        <button onClick={()=>{send()}}>Actualizar</button>
        </Form>
    </div>
    )
}